import {
    Component,
    Inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';
import { Moment } from 'moment';
import {
    TabStates,
    TabStateService
} from '../../../../shared/services/tabstate.service';
import {
    Subscription,
    interval,
    Observable
} from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TxSession } from '../../../../shared/interfaces/session';
import { AuthService } from '../../../../shared/services/auth.service';
import {
    TxBuchung,
    TxDeleteBooking
} from '../../../../shared/interfaces/bdebuchung';
import { TxApiService } from '../../../../shared/services/txapi.service';
import { CalendarService } from '../../../../shared/services/calendar.service';

declare var Messenger: any;

export interface TxSelectTypeDialogData {
    type: 'select' | 'bde' | 'pze';
    mustCreateWorkflow: [boolean, boolean];
    id?: number;
    date?: Moment;
    start?: Moment;
    end?: Moment;
    timemodel_id?: number;
    kst_number?: number;
    auftrag_nummer?: string,
    schritt_nummer?: string,
    note?: string;
    quantity?: number;
    teamLeaderFor?: boolean;
    bookProtocol?: [];
}

export class TxSchedulerSelectFormResult<T = TxSchedulerPzeResult | TxSchedulerBdeResult> {
    isValid: boolean;
    value: T
}

export class TxSchedulerPzeResult {
    id?: number;
    date: string;
    end: string;
    kst_number: number;
    note: string;
    start: string;
    timemodel_id: number;
}

export class TxSchedulerBdeResult {
    id?: number;
    auftrag_nummer: string;
    date: string;
    end: string
    note: string
    quantity: number;
    schritt_nummer: string
    start: string
}

@Component({
    templateUrl: './tx-select-type.dialog.component.html'
})
export class TxSelectTypeDialogComponent implements OnInit, OnDestroy {
    cutoffDate: string | null = null;
    isAfterCutoff = true;
    public SelectedType: 'bde' | 'pze' | null = null;
    private tabStateSubscription: Subscription;
    public selectedIndex = 0;
    displayedColumns: string[] = ['BookDate', 'BookType', 'BookError'];
    tabState: TabStates;
    tabState$: Observable<TabStates>;
    session: TxSession;
    mustCreatePze: boolean;
    mustCreateBde: boolean;
    public PzeFormData: TxSchedulerSelectFormResult<TxSchedulerPzeResult> = {
        isValid: false,
        value: null
    };

    public BdeFormData: TxSchedulerSelectFormResult<TxSchedulerBdeResult> = {
        isValid: false,
        value: null
    };

    constructor(
        private calendarService: CalendarService,
        public dialogRef: MatDialogRef<TxSelectTypeDialogComponent>,
        private myTxApiService: TxApiService,
        public myAuthService: AuthService,
        public myTabStateService: TabStateService,
        @Inject(MAT_DIALOG_DATA) public data: TxSelectTypeDialogData
    ) {
        this.myTxApiService.callAPI('getSetting', { settingName: 'BLOCK_DATE' }).subscribe(
            (response) => {
                if (response && response.settingString) {
                    this.cutoffDate = response.settingString;

                    const cutoff = new Date(this.cutoffDate);
                    const start = data.start.toDate();

                    this.isAfterCutoff = start > cutoff;
                }
            },
            (error) => {
                console.error('Fehler beim Laden des BLOCK_DATE:', error);
            }
        );
        console.log('Dialog received data:', data);
        this.session = myAuthService.getSession();

        this.PzeFormData.value = {
            id: this.data.id,
            date: this.data.start.format('YYYY-MM-DD'),
            start: this.data.start.format('HH:mm'),
            end: this.data.end.format('HH:mm'),
            kst_number: null,
            note: null,
            timemodel_id: null
        };

        this.BdeFormData.value = {
            id: this.data.id,
            date: this.data.start.format('YYYY-MM-DD'),
            start: this.data.start.format('HH:mm'),
            end: this.data.end.format('HH:mm'),
            note: null,
            auftrag_nummer: null,
            schritt_nummer: null,
            quantity: 0
        };


        switch (this.data.type) {
            case 'pze':
                this.myTabStateService.onTabOpen('pze1');
                this.myTabStateService.onTabClose('bde5');
                this.SelectedType = 'pze';
                this.PzeFormData.isValid = true;
                this.PzeFormData.value.note = this.data.note;
                this.PzeFormData.value.kst_number = this.data.kst_number;
                this.PzeFormData.value.timemodel_id = this.data.timemodel_id;
                break;
            case 'bde':
                this.myTabStateService.onTabOpen('bde5');
                this.myTabStateService.onTabClose('pze1');
                this.SelectedType = 'bde';
                this.BdeFormData.isValid = true;
                this.BdeFormData.value.note = this.data.note;
                this.BdeFormData.value.auftrag_nummer = this.data.auftrag_nummer;
                this.BdeFormData.value.schritt_nummer = this.data.schritt_nummer;
                this.BdeFormData.value.quantity = this.data.quantity;
                break;
            default:
                const tabActivities = localStorage.getItem('tabActives');
                if (tabActivities !== null) {
                    const tabActivesObj = JSON.parse(tabActivities);

                    const pze1Value = tabActivesObj.pze1;

                    if (pze1Value === undefined) {
                        this.myTabStateService.onTabOpen('pze1');
                        this.myTabStateService.onTabClose('bde5');
                    }
                }
                if (this.data.auftrag_nummer) {
                    this.BdeFormData.value.auftrag_nummer = this.data.auftrag_nummer;
                }
                break;
        }
    }

    onTabOpen(name :string) {
        this.myTabStateService.onTabOpen(name);
    }

    onTabClose(name :string) {
        this.myTabStateService.onTabClose(name);
    }

    validate(){
        if (this.SelectedType == 'pze'){
            return !this.PzeFormData.isValid === true
        }

        if (this.SelectedType == 'bde') {
            return !this.BdeFormData.isValid === true
        }

        return true;
    }

    ngOnInit(): void {
        const [mustCreatePze, mustCreateBde] = this.data.mustCreateWorkflow;
        this.mustCreatePze = mustCreatePze;
        this.mustCreateBde = mustCreateBde;
        if (!this.data.id) {
            this.tabStateSubscription = this.myTabStateService.TabStates$.subscribe(states => {
                if (states.pze1) {
                    this.selectedIndex = 0;
                    this.SelectedType = 'pze';
                } else if (states.bde5) {
                    this.selectedIndex = 1;
                    this.SelectedType = 'bde';
                }
            });
        }
    }

    onTabChange(event: any): void {
        const tabIndex = event.index;
        const tabName = this.getTabNameByIndex(tabIndex);

        this.myTabStateService.onTabOpen(tabName);

        const otherTabName = tabName === 'pze1' ? 'bde5' : 'pze1';
        this.myTabStateService.onTabClose(otherTabName);
    }

    getTabNameByIndex(index: number): string {
        const tabNames = ['pze1', 'bde5'];
        return tabNames[index];
    }

    getSaveButtonText() {
        const [mustCreatePze, mustCreateBde] = this.data.mustCreateWorkflow;

        if (!this.data.teamLeaderFor && (
            this.SelectedType === 'pze' && mustCreatePze ||
            this.SelectedType === 'bde' && mustCreateBde
        )) {
            return 'Beantragen';
        }
        return this.data.id ? 'Ändern' : 'Eintragen'
    }

    ngOnDestroy(): void {
        if (!this.data.id) {
            this.tabStateSubscription.unsubscribe();
        }
    }

    deleteEvent() {
        const deleteCheck = confirm('Möchten Sie diesen Eintrag wirklich löschen?');
        if (!deleteCheck) {
            return;
        }
        this.onCancel()
        const myDeleteBuchungsData = this.prepareDelete();
        const apiAnfrage: Observable<TxBuchung> = this.myTxApiService.callAPI('deleteBooking', myDeleteBuchungsData);
        apiAnfrage.subscribe(
            (mySessionData: any) => {
                Messenger().post({
                    message: mySessionData.statustext,
                    type: 'success',
                    showCloseButton: true,
                    hideAfter: 5,
                });
            },
            (error) => {
                Messenger().post({
                    message: 'ERROR' + error,
                    type: 'error',
                    showCloseButton: true,
                });
            },
        );
        this.calendarService.triggerRefreshCalendar();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    prepareDelete() {
        const myDeleteBuchungsData = new TxDeleteBooking();

        myDeleteBuchungsData.book_type = this.data.type;
        myDeleteBuchungsData.book_beginn_id = this.data.id.toString();
        myDeleteBuchungsData.book_end_id = this.data.id ? this.data.id.toString() : '0';

        return myDeleteBuchungsData;
    }

    setPzeData($event: TxSchedulerSelectFormResult<TxSchedulerPzeResult>) {
        if(typeof $event === 'object'){
            this.PzeFormData = $event
        }
    }

    setBdeData($event: TxSchedulerSelectFormResult<TxSchedulerBdeResult>) {
        if(typeof $event === 'object'){
            this.BdeFormData = $event
        }
    }
}
