/* tslint:disable:variable-name */
import { TeamLeaderEntity } from '../../store/entities/TeamLeaderEntity';

export class TxSession {
	constructor(
		public statuscode: number = 0,
		public statustext: string = '',
		public sessionid: string = '',
		public Buchungsarten: string = '',
		public Name: string = '',
        public Kommen: number = 0,
        public bookAuto: number = 0,
		public Pause: number = 0,
		public Workflow: number = 0,
		public Dienstgang: number = 0,
		public KST: number = 0,
		public BDE: number = 0,
		public personalnr: number = 0,
		public team_leader: number = 0,
		public appMode: string = '',
		public stellvertreter_aktiv: number = 0,
		public stellvertreter_name: string = '',
		public calendar_pze: number = 0,
		public calendar_bde: number = 0,
		public calendar: number = 0,
		public fscontrol: number = 0,
		public barcode: number = 0,
		public always_online: number = 0,
		public terminal_mode: number = 0,
		public workflow_version: number = 0,
		public iun: string = '',
		public reset_pw = 0,
		public calendar_pze_tl = 0,
		public calendar_bde_tl = 0,
		public project_office = 0,
		public planning = 0,
		public staff_office = 0,
		public staff_office_accounts = 0,
		public staff_office_file = 0,
		public staff_office_file_permissions = 0,
		public staff_office_timemodel = 0,
		public staff_office_tlg = 0,
		public messages_available = false
	) {}
}

export class TxUpdateStellvertreter {
	constructor(public sessionid: string = '', public active: number = 0, public teamleader_for: number = 0) {}
}

export class TxSetTeamleader {
	constructor(public teamleader: TeamLeaderEntity) {}
}

export class TxGetDataList {
	constructor(public selection_source: string = '') {}
}
