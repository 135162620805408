import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TxApiService } from '../../shared/services/txapi.service';
import { GetTeamleaderCalendar, SetTeamleader, SetTeamleaderCalendar } from '../actions/teamleader.actions';
import { map, switchMap } from 'rxjs/operators';
import { WorkflowService } from '../../shared/services/workflow.service';
import { LoadTeamleaderListRequest } from '../actions/personal-office.actions';
declare let Messenger: any;

@Injectable()
export class TeamleaderEffects {
	getWfCalendar$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GetTeamleaderCalendar),
			switchMap(({ fullyear, teamleader }) => this.workflowSvc.getWorkflowCalendar(fullyear, teamleader)),
			map((res) => {
				return SetTeamleaderCalendar(res.WfCalendar);
			})
		)
	);

	setTeamleader$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SetTeamleader),
			switchMap(({ teamleader }) =>
				this.http.callAPI('updateStellvertreter', {
					active: teamleader.deputy_active,
					teamleader_for: teamleader.persnr,
				})
			),
			map((res) => {
				console.log(res, 'test');
                if (res.statuscode !== 0){
                    Messenger().post({
                        message: res.statustext,
                        type: 'error',
                        showCloseButton: true,
                    });
                }

				return LoadTeamleaderListRequest();
			})
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly http: TxApiService,
		private readonly workflowSvc: WorkflowService
	) {}
}
