<div class="grid hidden md:flex">
	<div class="col-12 md:col-6">
		<ng-container [ngTemplateOutlet]="welcome"></ng-container>
	</div>
	<div class="col-12 md:col-6">
		<ng-container [ngTemplateOutlet]="userStatus"></ng-container>
	</div>
</div>

<div class="grid">
	<div class="col-12 md:col-6">
		<p-accordion (onOpen)="onTabOpen('b1')" (onClose)="onTabClose('b1')">
			<p-accordionTab
				[header]="'txBuchungen.timeRegistration' | translate"
				[selected]="tabState?.b1 == null || tabState?.b1 == true ? true : false">
				<section class="widget" widget>
					<div class="widget-body">
                        <div class="grid" *ngIf="myAuthService?.getSession()?.bookAuto == 1">
                            <div class="col-12">
                                <button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('K')" name="btnBuchung">
                                    {{ 'txBuchungen.booking' | translate }}
                                </button>
                            </div>
                        </div>
						<div class="grid" *ngIf="myAuthService?.getSession()?.Kommen == 1">
							<div class="col-12 md:col-6" *ngIf="myAuthService?.getSession()?.KST == 0">
								<button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('K')" name="btnKommen">
									Kommen
								</button>
							</div>

							<div class="col-12 lg:col-6" *ngIf="myAuthService?.getSession()?.KST == 1">
								<p-splitButton
									[label]="'txBuchungen.arrive' | translate"
									(onClick)="clickBookingButton('K')"
									[model]="kostenstellen"
									class="btn-primary"
									appendTo="body"
									styleClass="btn-lg-split"></p-splitButton>
								<!--
                                <button class="btn btn-primary btn-lg btn-block btn-relative" (click)="clickBookingButton('K')" name="btnKommen">
                                    Kommen</button>
                                    <p-dropdown ngDefaultControl
                                                name="myGuiKSTDropdown"
                                                id="myGuiKSTDropdown"
                                                styleClass="kstbutton"
                                                [options]="myKSTArray"
                                                [(ngModel)]="mycurrentKSTID"
                                                autoWidth=false
                                                (onChange)="onKSTSelected($event)"
                                    ></p-dropdown>

            -->
							</div>
							<div class="col-12 lg:col-6">
								<button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('G')">
									{{ 'txBuchungen.go' | translate }}
								</button>
							</div>
						</div>
						<div class="grid" *ngIf="myAuthService?.getSession()?.Pause == 1">
							<div class="col-12 lg:col-6">
								<button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('PB')">
									{{ 'txBuchungen.beginOfBreak' | translate }}
								</button>
							</div>
							<div class="col-12 lg:col-6">
								<button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('PS')">
									{{ 'txBuchungen.endOfBreak' | translate }}
								</button>
							</div>
						</div>
						<div class="grid" *ngIf="myAuthService?.getSession()?.Dienstgang == 1">
							<div class="col-12 lg:col-6">
								<button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('DB')">
									{{ 'txBuchungen.beginOfErrand' | translate }}
								</button>
							</div>
							<div class="col-12 lg:col-6">
								<button class="btn btn-primary btn-lg btn-block" (click)="clickBookingButton('DE')">
									{{ 'txBuchungen.endOfErrand' | translate }}
								</button>
							</div>
						</div>
					</div>
				</section>
			</p-accordionTab>
		</p-accordion>
		<!--<p-accordion *ngIf="currentDayEvents$ | async as events" (onOpen)="onTabOpen('b2')" (onClose)="onTabClose('b2')">
            <p-accordionTab [header]="'txBuchungen.currentDayEvents' | translate"
                            *ngIf="events?.length > 0 || QueuedBookings.length > 0"
                            [selected]="tabState?.b1 == null || tabState?.b1 == true">
                <section class="widget" widget>
                    <tx-event *ngFor="let event of events | filter:'title':'PZE'" [event]="event"></tx-event>
                    <tx-event *ngFor="let event of QueuedBookings" [event]="event"></tx-event>
                </section>
            </p-accordionTab>
        </p-accordion>-->
	</div>
	<div class="col-12 md:col-6" *ngIf="myAuthService?.getSession()?.BDE == 1">
		<tx-bde></tx-bde>
	</div>
</div>

<div class="grid hidden-md-up">
	<div class="col-12 md:col-6">
		<ng-container [ngTemplateOutlet]="userStatus"></ng-container>
	</div>
	<div class="col-12 md:col-6">
		<!--<tx-welcome [listOfUserInfo]="listOfUserInfo" [hideTitle]="true"></tx-welcome>-->
		<ng-container [ngTemplateOutlet]="welcome"></ng-container>
	</div>
</div>

<div class="grid" *ngIf="listOfUserInfo?.fs_control == 1 && (isMobile || isTablet) && goodConnection && !pinLoggedIn">
	<div class="col-12 md:col-6 disabled-exact" [ngClass]="{ 'disabled-overlay': !networkIsOnline }">
		<tx-imageupload
			(upload)="onFileUpload($event)"
			[(blockui)]="blockImageUploadUi"
			[forceDesktop]="false"
			title="Führerscheinkontrolle"
			description="Bitte erstellen Sie jetzt ein Foto von Ihrem Führerschein"
			chooseLabel="Fotoaufnahme starten"></tx-imageupload>
	</div>
</div>

<ng-template #welcome>
	<tx-welcome
		*ngIf="queueSize$ | async as queueSize"
		[offlineBookings]="queueSize.length"
		[listOfUserInfo]="listOfUserInfo"
		[hideTitle]="isMobile">
	</tx-welcome>
</ng-template>

<ng-template #userStatus>
	<ng-container *ngIf="!pinLoggedIn && myTabStateService.TabStates$ | async as TabStates">
        <mat-accordion>
            <mat-expansion-panel
                    class="mat-elevation-z0"
                    hideToggle
                    (opened)="onTabOpen('us1')"
                    (closed)="onTabClose('us1')"
                    [expanded]="TabStates.us1"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="flex space-x-2">
                            <div class="ml-1">
                                <div class="transition-all rotate-0 {{ TabStates.us1 ? 'rotate-90' : '' }}">
                                    <fa-icon icon="chevron-right"></fa-icon>
                                </div>
                            </div>
                            <div class="text-xl font-bold">
                                {{ 'txBuchungen.userState' | translate }}
                            </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div class="flex space-x-2">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'txBuchungen.chooseState' | translate }}</mat-label>
                            <mat-select [(ngModel)]="SelectedState" (selectionChange)="setUserState()">
                                <mat-option *ngFor="let state of UserStates$ | async" [value]="state.value">
                                    {{ state.label }}
                                </mat-option>
                            </mat-select>
                            <div matSuffix class="flex space-x-4">
<!--                                <mat-icon matRipple click-stop-propagation class="flex justify-center ml-2 mb-2 rounded-full cursor-pointer text-green-500 p-2 bg-green-500/20" style="height: 44px; width: 44px;" (click)="setUserState()">-->
<!--                                    <fa-icon icon="check"></fa-icon>-->
<!--                                </mat-icon>-->
                                <mat-icon matRipple click-stop-propagation class="flex justify-center ml-2 mb-2 rounded-full cursor-pointer text-red-500 p-2 bg-red-500/20" style="height: 44px; width: 44px;" (click)="SelectedState = '0'; setUserState()">
                                    <fa-icon icon="times"></fa-icon>
                                </mat-icon>
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
	</ng-container>
</ng-template>
