import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormatDate } from '../../shared/services/formatdate.service';
import {
	TxCreateWorkflowRequest,
	TxListOfWorkflowTyp,
	TxWorkflowTypen,
	TYPNEU,
} from '../../shared/interfaces/workflow';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { TxApiService } from '../../shared/services/txapi.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MessengerService } from '../../shared/services/messenger.service';
import { take } from 'rxjs/operators';

declare let jQuery: any;
declare var Messenger: any;

@Component({
	selector: 'tx-apply-booking',
	templateUrl: './tx-apply-booking.component.html',
})
export class TxApplyBookingComponent implements OnInit, OnChanges {
	@Input() myFormType: string;
	@Input() myBuchungsDate = '';
	@Output() submitClicked = new EventEmitter<boolean>();

	@ViewChild('antragForm') antragForm: NgForm;
	antragsForm = this.fb.group({
		currentListOfWorkflowTyp: [null, [Validators.required]],
		vonAntragField: [null, [Validators.required]],
		bisAntragField: [null, [Validators.required]],
		Notiz: [null],
	});

	@ViewChild('buchungForm') buchungForm: NgForm;
	buchungsForm = this.fb.group({
		vonBuchungDatumField: [null, [Validators.required]],
		currentTypeNeuItems: [null, [Validators.required]],
		vonBuchungZeitField: [null, [Validators.required]],
		Notiz: [null],
	});

	de: any;
	date: Date;
    minDate: Date | null = null;
    cutoffDate: string | null = null;
	myListOfWorkflowTyp: Array<TxListOfWorkflowTyp> = null;
	myListOfWorkflowData: TxWorkflowTypen;

	myCreateAntragModell: TxCreateWorkflowRequest = new TxCreateWorkflowRequest();
	myCreateBuchungModell: TxCreateWorkflowRequest = new TxCreateWorkflowRequest();

	TypeNeuItems: Array<{ label: string; typ: string; value: number }> = [];
	currentTypeNeuItems = null;
	placeholderApplyBooking = '';

	viewClockPicker = false;

	constructor(
		private myTxApiservice: TxApiService,
		private formatDate: FormatDate,
		private translate: TranslateService,
        private myTxApiService: TxApiService,
		private myMessengerService: MessengerService,
		private fb: FormBuilder
	) {
        this.myTxApiService.callAPI('getSetting', { settingName: 'BLOCK_DATE' }).subscribe(
            (response) => {
                if (response && response.settingString) {
                    this.cutoffDate = response.settingString;
                    const [year, month, day] = this.cutoffDate.split('-').map(Number);
                    this.minDate = new Date(year, month - 1, day + 1);
                } else {
                    this.minDate = null;
                }
            },
            (error) => {
                console.error('Fehler beim Laden des BLOCK_DATE:', error);
                this.minDate = null;
            }
        );
    }

	getCurrentTimeString() {
		return moment().format('HH:mm');
	}

	/*timeSelected(event: string) {
		this.picker.timeSet.emit(event);
		this.picker.close();
	}*/

	updateWorkflow() {
		this.submitClicked.emit();
	}

	selectStatus(event: any): void {
		this.myCreateBuchungModell.Art = this.getStatusByID(event.value);
	}

	onChangeFzb(aEvent: any): void {
		this.myCreateAntragModell.Typ = aEvent.value;
	}

	getStatusByID(aID: number) {
		let ret = null;
		for (let i = 0, l = this.TypeNeuItems.length; i < l; i++) {
			if (this.TypeNeuItems[i].value === aID) {
				ret = this.TypeNeuItems[i].typ;
				break;
			}
		}
		return ret;
	}

	dateFromSelected(aValue) {
		this.myCreateAntragModell.bisAntragField = aValue;
	}

	createWorkflow(myArt?: string): void {
		let myRequest: Observable<any>;
		let myResponse: any;

		if (myArt === 'FZB') {
			/* Fehlzeit nachbuchen /nachstempeln*/
			this.myCreateAntragModell.Art = myArt;
			this.myCreateAntragModell.Typ = this.antragsForm.value.currentListOfWorkflowTyp.toString();
			this.myCreateAntragModell.vonAntragField = this.antragsForm.value.vonAntragField.toString();
			this.myCreateAntragModell.bisAntragField = this.antragsForm.value.bisAntragField.toString();
			this.myCreateAntragModell.Notiz = this.antragsForm.value.Notiz?.toString() ?? null;

			this.myCreateAntragModell.von = FormatDate.createTxDate(this.antragsForm.value.vonAntragField.toString());
			this.myCreateAntragModell.bis = FormatDate.createTxDate(this.antragsForm.value.bisAntragField.toString());
			myRequest = this.myTxApiservice.callAPI('createWorkflow', this.myCreateAntragModell);
		} else {
			/* Workflow Antrag */
			this.myCreateBuchungModell.vonBuchungZeitField = this.buchungsForm.value.vonBuchungZeitField;
			this.myCreateBuchungModell.vonBuchungDatumField = this.buchungsForm.value.vonBuchungDatumField;
			this.myCreateBuchungModell.Notiz = this.buchungsForm.value.Notiz;

			this.myCreateBuchungModell.von = FormatDate.createTxDate(
				this.buchungsForm.value.vonBuchungDatumField,
				this.buchungsForm.value.vonBuchungZeitField + ':00'
			);
			myRequest = this.myTxApiservice.callAPI('createWorkflow', this.myCreateBuchungModell);
		}

		myRequest.toPromise().then(
			(myData) => {
				myResponse = myData;
				this.resetForms(myArt);
				// setTimeout(this.getWorkflow(), 50);
				let responseStatus = 'error';
				if (myResponse.statuscode === 0) {
					responseStatus = 'success';
				}
				this.myMessengerService.message(myResponse.statustext, responseStatus as MessengerType);
			},
			(error) => {
				console.log('ERROR' + error);
				/*Messenger().post({
					message: myResponse.error,
					type: 'success',
					showCloseButton: true,
					hideAfter: 5,
				});*/
			}
		);
	}

	resetForms(type?) {
		if (type === 'FZB') {
			this.antragsForm.reset();
			this.antragsForm.markAsUntouched();
			this.antragForm.resetForm();
		} else {
			this.buchungsForm.reset();
			this.buchungsForm.markAsUntouched();
			this.buchungForm.resetForm();
		}
	}

	ngOnChanges() {
		if (this.myBuchungsDate.length !== 0) {
			this.myCreateBuchungModell.vonBuchungDatumField = new Date(this.myBuchungsDate);
		}
	}

	ngOnInit() {
		setTimeout(() => {
			this.viewClockPicker = true;
		}, 200);
		this.myTxApiservice
			.callAPI('getWorkflowTyp', {})
			.pipe(take(1))
			.subscribe(
				(result) => {
					this.myListOfWorkflowData = new TxWorkflowTypen(
						result.statuscode,
						result.statustext,
						result.ListOfWorkflowTyp
					);
					this.myListOfWorkflowTyp = this.myListOfWorkflowData.ListOfWorkflowTyp;
				},
				(error) => {
					console.log('ERROR' + error);
				}
			);

		Messenger.options = {
			extraClasses: 'messenger-fixed messenger-on-top',
			theme: 'flat',
		};

		this.de = {
			firstDayOfWeek: 1,
			dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			monthNames: [
				'Januar',
				'Februar',
				'März',
				'April',
				'Mai',
				'Juni',
				'Juli',
				'August',
				'September',
				'OKtober',
				'November',
				'Dezember',
			],
			monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		};

		/*this.TypeNeuItems.push({
			value: null,
			label: 'Bitte Buchungsart wählen',
			typ: '',
		});*/

		TYPNEU.forEach((TypNeu: { label: string; Typ: string; value: number }) => {
			this.TypeNeuItems.push({
				value: TypNeu.value,
				label: TypNeu.label,
				typ: TypNeu.Typ,
			});
		});

		// this.currentTypeNeuItems = this.TypeNeuItems[0];
	}

	testClose(event) {}
}
