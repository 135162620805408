import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { AuthService } from '../../shared/services/auth.service';
import { TxSession } from '../../shared/interfaces/session';
import { InfocenterProtokoll } from '../../shared/interfaces/protokollInfocenter';
import { InfocenterService } from '../../shared/services/infocenter.service';
import { Observable, Subscription } from 'rxjs';
import { UserInfoService } from '../../shared/services/user-info.service';
import { ListOfUserInfo } from '../../shared/interfaces/user-info';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getSession } from '../../store/selectors/user.selectors';
import { MenuItem } from 'primeng/api';
import { filter, map, tap } from 'rxjs/operators';
import { TerminalService } from '../../shared/services/terminal.service';

declare var jQuery: any;

@Component({
	selector: 'tx-navbar',
	templateUrl: './navbar.template.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
	@Input() pinMode = false;
	@Output() toggleSidebarEvent: EventEmitter<any> = new EventEmitter();
	@Output() toggleChatEvent: EventEmitter<any> = new EventEmitter();
	$el: any;
	config: any;
	session$: Observable<TxSession>;
	myInfocenter: InfocenterProtokoll = null;
	userInfoSubscription: Subscription;
	theCurrentUserInfo$: Observable<ListOfUserInfo>;
	offlineAlert = false;
	networkIsOnline$: Observable<boolean>;
    badgeNumber = 0;

	menuItems: MenuItem[] | null = null;

	constructor(
		private el: ElementRef,
		private myAppConfig: AppConfigService,
		private router: Router,
		private myAuthService: AuthService,
		private terminalService: TerminalService,
		private myInfocenterService: InfocenterService,
		private myUserInfoService: UserInfoService,
		private myConnectionStateService: ConnectionStateService,
		private translateService: TranslateService,
		private translate: TranslatePipe,
		private store: Store<State>
	) {
		this.session$ = store.select(getSession);

		this.$el = jQuery(el.nativeElement);
		this.config = this.myAppConfig.getConfig();
		this.myUserInfoService.catchUserInfoFromServer(false, true);
		this.theCurrentUserInfo$ = this.myUserInfoService.getUserInfo().pipe(
			filter((info) => info.hasOwnProperty('ListOfUserInfo')),
			map((info) => info.ListOfUserInfo[0])
		);
		/*this.userInfoSubscription = this.myUserInfoService.getUserInfo().subscribe((myUserInfo) => {
            if (myUserInfo.hasOwnProperty('ListOfUserInfo')) {
                if (myUserInfo.ListOfUserInfo[0].status !== this.theCurrentUserInfo?.status) {
                    this.theCurrentUserInfo = myUserInfo.ListOfUserInfo[0];
                    // this.changeRef.detectChanges();
                }
            }
        });*/
		this.networkIsOnline$ = this.myConnectionStateService.onlineState;
		/*this.myConnectionStateService.onlineState.subscribe((onlineState) => {
            if (this.networkIsOnline !== onlineState) {
                this.networkIsOnline = onlineState;
                // this.changeRef.detectChanges();
            }
        });*/
	}

	toggleOfflineAlert() {
		this.offlineAlert = !this.offlineAlert;
	}

	toggleSidebar(state): void {
		this.toggleSidebarEvent.emit(state);
	}

	toggleChat(): void {
		this.toggleChatEvent.emit(null);
		this.myInfocenterService.catchInfoCenterFromServer();
	}

	ngOnInit(): void {
        this.myUserInfoService.getUserInfo().subscribe((myUserInfo) => {
            if (myUserInfo.statustext === 'OK' && Array.isArray(myUserInfo.ListOfUserInfo)) {
                const listUser = myUserInfo.ListOfUserInfo[0];
                this.badgeNumber = listUser['tl_open_workflow'] + listUser['unread_messages'] + listUser['usr_wf_count'];
            }
        });

		setTimeout(() => {
			const $chatNotification = jQuery('#chat-notification');
			$chatNotification
				.removeClass('hide')
				.addClass('animated fadeIn')
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
					$chatNotification.removeClass('animated fadeIn');
					setTimeout(() => {
						$chatNotification
							.addClass('animated fadeOut')
							.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd' + ' oanimationend animationend', () => {
								$chatNotification.addClass('hide');
							});
					}, 8000);
				});
			$chatNotification.siblings('#toggle-chat').append('<i class="chat-notification-sing animated bounceIn"></i>');
		}, 4000);

		this.$el.find('.input-group-addon + .form-control').on('blur focus', function (e): void {
			jQuery(this).parents('.input-group')[e.type === 'focus' ? 'addClass' : 'removeClass']('focus');
		});
	}

	logout() {
		this.terminalService.pinLoggedInSubject.next(false);
		this.myAuthService.logout();
	}

	reload() {
		location.reload();
	}

	changeLang(language: string = 'de') {
		this.translateService.use(language);
	}

	alert(s: any) {
		window.alert(s);
	}
}
