import {
    Component,
    Input,
    Inject,
    Output,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import 'twix';
import { TxSession } from '../../../shared/interfaces/session';
import { AuthService } from '../../../shared/services/auth.service';
import {
    BehaviorSubject,
    Observable,
    startWith,
    Subscription,
    switchMap
} from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TxBdeKST } from '../../../shared/interfaces/buchungen';
import { AppConfigService } from '../../../shared/services/appconfig.service';
import { TxApiService } from '../../../shared/services/txapi.service';
import {
    FormBuilder,
    FormControl,
    Validators
} from '@angular/forms';
import {
    debounceTime,
    map,
    take,
    withLatestFrom
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CalendarEntityConfig } from '../../../shared/interfaces/PersonalAccountsConfig';
import {
    getContracts
} from '../../../store/selectors/data.selectors';
import { ContractEntity } from '../../../store/entities/ContractEntity';
import {
    TxSchedulerBdeResult,
    TxSchedulerSelectFormResult
} from '../dialogs/tx-select-type-dialog/tx-select-type.dialog.component';
import moment from 'moment/moment';
import { MatSelect } from '@angular/material/select';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { AuftragsSchrittResponse } from '../../../store/entities/Api/ListOfAuftragResponse';

@Component({
    selector: 'app-tx-scheduler-bde',
    templateUrl: './tx-scheduler-bde.component.html'
})
export class TxSchedulerBdeComponent implements OnInit, OnDestroy{
    minDate: Date | null = null;
    cutoffDate: string | null = null;
    searchControlContract = new FormControl('');
    filteredOptions: Observable<AuftragsSchrittResponse[]>;
    constructor(
        public readonly authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly fb: FormBuilder,
        private myTxApiService: TxApiService,
        private store: Store,
        private readonly localStorageService: LocalStorageService
    ) {
        this.myTxApiService.callAPI('getSetting', { settingName: 'BLOCK_DATE' }).subscribe(
            (response) => {
                if (response && response.settingString) {
                    this.cutoffDate = response.settingString;
                    const [year, month, day] = this.cutoffDate.split('-').map(Number);
                    this.minDate = new Date(year, month - 1, day + 1);
                } else {
                    this.minDate = null;
                }
            },
            (error) => {
                console.error('Fehler beim Laden des BLOCK_DATE:', error);
                this.minDate = null;
            }
        );
        console.log('Dialog received data:', data, this.ngModel);

        this.Session = authService.getSession();

        this.Contracts$ = this.AuftragSearch$
            .asObservable()
            .pipe(
                withLatestFrom(this.store.select(getContracts)),
                startWith(['', []] as [string, ContractEntity[]]),
                debounceTime(300),
                map(([search, contracts]) => {
                    if (!search)
                        return contracts;

                    return contracts
                        .filter((auftrag) => {
                            return auftrag.bezeichnung.toLowerCase().includes(search.toLowerCase());
                        })
                        .slice(0, 100);
                })
            );

        this.formSub = this.bdeForm.valueChanges.subscribe((value) => {
            // console.log(this.ngModel, value, {
            //     ...this.ngModel.value,
            //     ...value
            // });

            this.ngModelChange.emit({
                isValid: this.bdeForm.valid,
                value: {
                    ...this.ngModel.value,
                    ...value
                }
            });
        })
    }
    @Input() date: string = moment().format('YYYY-MM-DD');
    @Input() startTime = '08:00';
    @Input() endTime = '16:30';

    @Input() ngModel: TxSchedulerSelectFormResult<TxSchedulerBdeResult>;
    @Output() ngModelChange = new EventEmitter<TxSchedulerSelectFormResult<TxSchedulerBdeResult>>();

    private AuftragSearch$ = new BehaviorSubject<string>(null);

    public Contracts$: Observable<ContractEntity[]>;
    searchControl = new FormControl();
    public Session: TxSession;
    public bdeForm = this.fb.group({
        auftrag_nummer: [null, Validators.required],
        schritt_nummer: [null, Validators.required],
        date: [null, Validators.required],
        start: [null, Validators.required],
        end: [null, Validators.required],
        quantity: [0],
        note: [null],
    });

    private formSub: Subscription;
    @ViewChild('auftragSelect') auftragSelect: MatSelect;
    @ViewChild('contractSelect') contractSelect: MatSelect;

    ngOnDestroy(): void {
        this.formSub.unsubscribe();
    }

    public getContractSteps(auftragsnummer: string, contracts: ContractEntity[]) {
        return contracts.find(s => s.auftragsnummer === auftragsnummer)?.ListOfAuftragsschritt;
    }

    ngOnInit() {
        // console.log(this.ngModel);
        this.bdeForm.patchValue({
            date: this.date,
            start: this.startTime,
            end: this.endTime,
            auftrag_nummer: this.ngModel.value.auftrag_nummer,
            schritt_nummer: this.ngModel.value.schritt_nummer,
            quantity: this.ngModel.value.quantity ?? 0,
            note: this.ngModel.value.note
        });

        this.filteredOptions = this.searchControlContract.valueChanges.pipe(
            startWith(''),
            switchMap(value => this._filter(value || ''))
        );

    }

    private _filter(value: string): Observable<AuftragsSchrittResponse[]> {
        const filterValue = value.toLowerCase();

        return this.Contracts$.pipe(
            map(contracts => {
                // Suche nach dem entsprechenden Contract anhand der auftrag_nummer
                const auftrag_nummer = this.bdeForm.get('auftrag_nummer').value;
                const auftragsschritte = this.getContractSteps(auftrag_nummer, contracts) || [];

                // Filterung der ListOfAuftragsschritt basierend auf dem eingegebenen Wert
                return auftragsschritte.filter(schritt =>
                    schritt.schrittbezeichnung.toLowerCase().includes(filterValue)
                );
            })
        );
    }

    onSearchAuftrag(event: KeyboardEvent) {
        if (event.target instanceof HTMLInputElement) {
            this.AuftragSearch$.next(event.target.value);
        }
    }

    clearSearch() {
        this.localStorageService.set('bdeAuftragNummer', this.bdeForm.value['auftrag_nummer']);
        this.searchControl.setValue('');  // Setzt das Suchfeld zurück
        this.searchControlContract.setValue('');  // Setzt das Suchfeld zurück
        this.AuftragSearch$.next('')
    }
}
