import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    concatMap,
    forkJoin,
    lastValueFrom,
    mergeMap,
    Observable,
    of,
    ReplaySubject,
    startWith,
    Subject,
    switchMap,
} from 'rxjs';
import {
    ListOfWorkflowGroup,
    TxCreateWorkflowRequest,
    TxListOfWorkflowTyp
} from '../../../shared/interfaces/workflow';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PlanCalendarEntity } from '../../../store/entities/PlanCalendarEntity';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {
    catchError,
    debounceTime,
    delay,
    filter,
    map,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import {
    CalendarOptions,
    createElement,
    EventInput,
    FullCalendarComponent
} from '@fullcalendar/angular';
import moment, { Moment } from 'moment';
import { MDCTooltip } from '@material/tooltip';
import { Store } from '@ngrx/store';
import { WorkflowService } from '../../../shared/services/workflow.service';
import { PlansService } from '../../../shared/services/plans.service';
import { AppConfigService } from '../../../shared/services/appconfig.service';
import { TxApiService } from '../../../shared/services/txapi.service';
import { getPlanCalendar } from '../../../store/selectors/plans.selectors';
import { ResourceInput } from '@fullcalendar/resource-common';
import {
    ListOfCalendarBDE,
    ListOfCalendarHead,
    ListOfCalendarPZE
} from '../../../shared/interfaces/scheduler';
import chroma from 'chroma-js';
import {
    ActivatedRoute,
    Route,
    Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TxTimeTrackingDialogComponent } from '../../dialogs/tx-time-tracking/tx-time-tracking.dialog';
import { MatDialog } from '@angular/material/dialog';
import { DateSelectArg } from '@fullcalendar/common';
import { MessengerService } from '../../../shared/services/messenger.service';
import {
    TxCreateBooking,
    TxUpdateBooking
} from '../../../shared/interfaces/bdebuchung';
import { ApiResponseType } from '../../../store/entities/Api/ApiResponse';
import { GlobalConstants } from '../../../globalConstants';
import { TxTeamleiterFscDialogComponent } from '../../tx-teamleiter/dialogs/tx-teamleiter-fsc/tx-teamleiter-fsc.dialog.component';
import {
    TxSchedulerFzDialogComponent,
    TxSchedulerFzDialogData,
    TxSchedulerFzDialogResult
} from '../../tx-scheduler/dialogs/tx-scheduler-fz/tx-scheduler-fz.dialog.component';
import { AuthService } from '../../../shared/services/auth.service';
import {
    TxSchedulerBdeResult,
    TxSchedulerPzeResult,
    TxSchedulerSelectFormResult,
    TxSelectTypeDialogComponent,
    TxSelectTypeDialogData
} from '../../tx-scheduler/dialogs/tx-select-type-dialog/tx-select-type.dialog.component';
import { TxSession } from '../../../shared/interfaces/session';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { LoadTimemodels } from '../../../store/actions/personal-office.actions'
import { PersonalOfficeService } from '../../../shared/services/personal-office.service';
import { DataService } from '../../../shared/services/data.service';
import { TimemodelEntity } from '../../../store/entities/TimemodelEntity';
import { getTimemodels } from '../../../store/selectors/personal-office.selectors';
import { TxBdeKST } from '../../../shared/interfaces/buchungen';
import { ListOfKSTEntity } from '../../../store/entities/ListOfKSTEntity';
import { ContractEntity } from '../../../store/entities/ContractEntity';
import { getContracts } from '../../../store/selectors/data.selectors';
import { ComponentType } from '@angular/cdk/overlay';
import { TxSchedulerComponent } from '../../tx-scheduler/tx-scheduler.component';
import {
    MatDatepicker,
    MatDatepickerInputEvent
} from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';

declare var Messenger: any;

@Component({
    selector: 'tx-po-accounts',
    templateUrl: './tx-po-accounts.component.html',
    styleUrls: ['./tx-po-accounts.component.scss'],
})
export class TxPoAccountsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    @ViewChild('picker') datePicker: MatDatepicker<Date>;
    @ViewChild('dateInput') dateInput: ElementRef
    dateControl = new FormControl();

    public workflowGroups$: Observable<Array<ListOfWorkflowGroup>>;
    public workflows$: Observable<PlanCalendarEntity[]>;

    public SelectedGroups: number[] = [];

    public selectedGroups$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);

    public accountInfos$ = new BehaviorSubject<{
        teamleader_for: number;
        begin_date: Date;
        end_date: Date;
    } | null>(null);
    private cutoffDate: string;
    private cutoffDateLoaded$ = new BehaviorSubject<boolean>(false);
    public CalendarOptions: CalendarOptions = {
        eventClick: this.eventClick.bind(this),
        schedulerLicenseKey: GlobalConstants.fullCalendarLicenseKey,
        plugins: [resourceTimelinePlugin],
        locale: 'de',
        headerToolbar: {
            left: 'refresh today customPrev,customNext datePicker',
            center: 'title',
            right: 'viewDay,viewWeek',
        },
        initialView: 'resourceTimelineDay',
        themeSystem: 'bootstrap',
        customButtons: {
            datePicker: {
                text: '',
                bootstrapFontAwesome: 'fa-calendar',
                click: () => {
                    this.datePicker.open();
                }
            },

            refresh: {
                text: 'Reload',
                bootstrapFontAwesome: 'fa-sync',
                click: (ev: MouseEvent, element: HTMLElement) => {
                    this.calendarComponent.getApi().refetchEvents();
                },
            },
            today: {
                text: this.translate.instant('txWorkflowCalender.today'),
                click: () => {
                    this.headerDataSubject.next({});
                    this.calendarComponent.getApi().gotoDate(new Date());
                    this.dateControl.setValue(new Date());
                    this.router.navigateByUrl(
                        `/personaloffice/personalkonten/${ moment().format('YYYY-MM-DD') }`
                    );
                },
            },
            customPrev: {
                text: '<',
                click: () => {
                    this.headerDataSubject.next({});

                    this.calendarComponent.getApi().prev();
                    const newDate = this.calendarComponent.getApi().getCurrentData().currentDate;
                    this.dateControl.setValue(newDate);
                    this.router.navigateByUrl(
                        `/personaloffice/personalkonten/${moment(newDate).format('yyyy-MM-DD')}`
                    );
                },
            },
            customNext: {
                text: '>',
                click: () => {
                    this.headerDataSubject.next({});

                    this.calendarComponent.getApi().next();
                    const newDate = this.calendarComponent.getApi().getCurrentData().currentDate;
                    this.dateControl.setValue(newDate);
                    this.router.navigateByUrl(
                        `/personaloffice/personalkonten/${moment(newDate).format('yyyy-MM-DD')}`
                    );
                },
            },
            viewDay: {
                text: this.translate.instant('txScheduler.day'),
                click: (mouseEvent, htmlElement) => {
                    this.headerDataSubject.next({});
                    this.calendarComponent.getApi().changeView('resourceTimelineDay');
                    this.calendarComponent.getApi().refetchEvents();
                    this.appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
                        config.SelectedView = 'resourceTimelineDay';
                        this.appConfig.setPersonalAccountsConfig(config);
                    });

                    this.router.navigateByUrl(
                        `/personaloffice/personalkonten/${ moment(
                            this.calendarComponent.getApi().getCurrentData().currentDate
                        ).format('yyyy-MM-DD') }`
                    );
                    htmlElement.parentNode.querySelector('.fc-state-active')?.classList.remove('fc-state-active');
                    htmlElement.classList.add('fc-state-active');
                },
            },
            viewWeek: {
                text: this.translate.instant('txScheduler.week'),
                click: (mouseEvent, htmlElement) => {
                    this.headerDataSubject.next({});
                    this.calendarComponent.getApi().changeView('resourceTimelineWeek');
                    this.calendarComponent.getApi().refetchEvents();
                    this.appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
                        config.SelectedView = 'resourceTimelineWeek';
                        this.appConfig.setPersonalAccountsConfig(config);
                    });

                    this.router.navigateByUrl(
                        `/personaloffice/personalkonten/${ moment(
                            this.calendarComponent.getApi().getCurrentData().currentDate
                        ).format('yyyy-MM-DD') }`
                    );
                    htmlElement.parentNode.querySelector('.fc-state-active')?.classList.remove('fc-state-active');
                    htmlElement.classList.add('fc-state-active');
                },
            },
        },
        resourceAreaColumns: [
            {
                field: 'select',
                headerContent: (args) => {
                    return createElement('input', {
                        type: 'checkbox',
                        className: 'multi-user-select-all-cb',
                        onClick: (args) => {
                            const el: HTMLInputElement = args.target;
                            let selector = '.multi-user-select-cb';
                            if (el.checked) {
                                selector += ':not(:checked)';
                            } else {
                                selector += ':checked';
                            }

                            this.MultiSelectUserIds = [];

                            document.querySelectorAll(selector).forEach((e: HTMLInputElement) => {
                                e.click();
                            });
                        },
                    });
                },
                width: 30,
                cellContent: (args) => {
                    return createElement('input', {
                        type: 'checkbox',
                        value: args.resource.id,
                        className: 'multi-user-select-cb',
                        onClick: () => {
                            this.toggleMultiSelect(args.resource.id);
                        },
                    });
                },
            },
            {
                field: 'title',
                headerContent: 'Name',
                cellContent: (args) => {
                    // [matTooltip]="person.name" matTooltipPosition="above" [matTooltipShowDelay]="1000"
                    const element = document.createElement('a');
                    element.onclick = (event) => {
                        this.router.navigate(['/kalender', moment(args.view.currentStart).format(
                            'yyyy-MM-DD'
                        ), args.resource.id]);
                        event.stopImmediatePropagation();
                        event.stopPropagation();
                        return false;
                    };
                    element.href = ['/#/kalender', args.resource.id, moment(args.view.currentStart).format(
                        'yyyy-MM-DD'
                    )].join('/');
                    element.innerText = args.resource.title;

                    return {
                        domNodes: [element]
                    };
                },
            },
            {
                field: 'info',
                headerContent: (args) => {
                    return args.view.type === 'resourceTimelineDay' ? 'Tagesinfo' : 'Wocheninfo';
                },
                cellContent: (args) => {
                    return createElement('span', {
                        style: {
                            fontSize: '10px',
                        },
                        dangerouslySetInnerHTML: {
                            __html: this.accountInfo[+args.resource.id] ?? '',
                        },
                    });
                },
            },
        ],
        eventDidMount: (info) => {
            if (!info.timeText) {
                info.timeText = `${ moment(info.event.start).format('HH:mm') } - ${ moment(info.event.end).format('HH:mm') }`;
            }
            const event_id = `event-tooltip-${ info.event.id }`;

            info.el.setAttribute('aria-describedby', event_id);
            if (!document.querySelector('#' + event_id)) {
                const tt = document.createElement('div');
                tt.id = event_id;
                tt.className = 'mdc-tooltip event-tooltip';
                tt.setAttribute('role', 'tooltip');
                tt.setAttribute('aria-hidden', 'true');

                const inner = tt.appendChild(document.createElement('div'));
                inner.className = 'mdc-tooltip__surface mdc-tooltip__surface-animation';

                inner.innerText = `${ info.timeText }: ${ info.event.title }`;

                if (info.event.extendedProps.type === 'FZ') {
                    inner.innerText = info.event.extendedProps.tooltip ?? '';
                }

                document.body.appendChild(tt);
                new MDCTooltip(tt);
            } else if (document.querySelector('#' + event_id)) {
                if (info.timeText.toLowerCase().includes('invalid')) {

                    const tooltipElement = document
                        .querySelector('#' + event_id)
                        .querySelector<HTMLElement>('.mdc-tooltip__surface');

                    tooltipElement.innerText = info.event.extendedProps.type === 'FZ'
                        ? info.event.extendedProps.tooltip
                        : info.event.title;

                }
                new MDCTooltip(document.querySelector('#' + event_id));
            }

            if (info.el.getBoundingClientRect().width < 85) {
                info.el.innerHTML = '';
                info.el.classList.add('event-tooltip-short');
                const icon = document.createElement('i');
                icon.className = 'fas fa-mouse-pointer';
                icon.style.color = info.event.textColor;
                info.el.appendChild(icon);
            }

            if (info.event.extendedProps.info) {
                const descriptionContainer = document.createElement('div');
                descriptionContainer.className = 'fc-description inline';
                descriptionContainer.innerHTML = info.event.extendedProps.info;
                info.el.querySelector<HTMLElement>('.fc-event-title-container .fc-event-title')?.prepend(descriptionContainer);
                info.el.querySelector<HTMLElement>('.fc-event-title-container .fc-event-title')?.classList.add('flex', 'justify-center');
            }
        },
        weekNumbers: true,
        weekNumberCalculation: 'ISO',
        weekText: 'KW',
        weekTextLong: 'Woche',
        views: {
            resourceTimelineDay: {
                displayEventTime: true,
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                },
                titleFormat: {
                    day: '2-digit',
                    month: 'long',
                    weekday: 'long',
                    year: 'numeric',
                },
                buttonText: 'Tag',
                slotDuration: { hours: 1 },
                slotLabelFormat: [
                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                ],
                slotLabelContent: (hookProps) => hookProps.text.replace(' Uhr', ''),
                select: (info) => this.selectDateRange(info),
            },
            resourceTimelineWeek: {
                type: 'resourceTimeline',
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                },
                titleFormat: {
                    month: 'long',
                    day: '2-digit',
                    week: 'narrow',
                    year: 'numeric',
                },
                slotLabelContent: (arg) => {
                    const dateKey = moment(arg.date).format('YYYY-MM-DD');

                    if (Object.keys(this.headerDataSubject.getValue()).length === 0) {
                        const schedulerDateRange = {
                            von: moment(this.calendarComponent.getApi().view.currentStart).format('YYYY-MM-DDTHH:mm:ss'),
                            bis: moment(this.calendarComponent.getApi().view.currentEnd).format('YYYY-MM-DDTHH:mm:ss'),
                            team_leader_for: this.teamLeaderFor,
                        };

                        this.myTxApiService.callAPI('getCalendarHead', schedulerDateRange).pipe(
                            take(1),
                            map((res) => {
                                const headerData = {};
                                res.ListOfCalendarHead
                                    .filter(data => data.special_day !== null)
                                    .forEach(data => {
                                        const date = moment(data.datum).format('YYYY-MM-DD');
                                        headerData[date] = data.special_day;
                                    });
                                return headerData;
                            })
                        ).subscribe(headerData => {
                            this.headerDataSubject.next(headerData);
                        });
                    }

                    const specialDay = this.headerDataSubject.getValue()[dateKey];

                    if (specialDay) {
                        return {
                            html: `<span class="txc-cal-specialdaycolor">${arg.text} (${specialDay})</span>`
                        };
                    }

                    return arg.text;
                },
                buttonText: 'Woche',
                slotDuration: { days: 1 },
                slotLabelFormat: {
                    weekday: 'short',
                    day: '2-digit',
                    omitCommas: true,
                },
                select: (info) => this.selectDateRange(info),
            },
        },
        firstDay: 1,
        contentHeight: 'auto',
        scrollTime: '12:00',
        allDaySlot: true,
        defaultAllDay: true,
        editable: false,
        selectable: true,
        nowIndicator: true,
        events: [],
        resources: [],
    };
    private headerDataSubject = new BehaviorSubject<{[key: string]: string}>({});
    private accountInfo: {
        [key: number]: string;
    } = {};

    public ShowDayBalance = true;
    public MultiSelectUserIds: string[] = [];
    isButtonVisible = false;
    mouseX = 0;
    mouseY = 0;
    listId = '';
    bookProtocol = [];
    data: DateSelectArg;
    currentData: DateSelectArg;
    teamLeaderFor: number;
    session: TxSession;
    timemodels$: Observable<TimemodelEntity[]>;
    BdeKst$: Observable<ListOfKSTEntity[]>;
    public Contracts$: Observable<ContractEntity[]>;
    private AuftragSearch$ = new BehaviorSubject<string>(null);
    private fetchEventsTimeout: any;

    constructor(
        private personalOfficeService: PersonalOfficeService,
        private myTxApiService: TxApiService,
        private dataService: DataService,
        private renderer: Renderer2,
        public myAuthService: AuthService,
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly workflowService: WorkflowService,
        private readonly planService: PlansService,
        private readonly appConfig: AppConfigService,
        private readonly api: TxApiService,
        private readonly _ngZone: NgZone,
        private readonly dialog: MatDialog,
        private readonly translate: TranslateService,
        private readonly messenger: MessengerService,
        private readonly localStorageService: LocalStorageService
    ) {
        this.session = myAuthService.getSession();

        this.accountInfos$
            .asObservable()
            .pipe(
                filter(Boolean),
                concatMap((d) => this.api.callAPI('getTimeAccountinfo', d).pipe(map((res) => ({ res, d }))))
            )
            .subscribe((data) => {
                this.accountInfo[data.d.teamleader_for] = data.res.infotext;
                this.calendarComponent.getApi().render();
            });

        appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
            this.SelectedGroups = config.SelectedGroups;
            this.selectedGroups$.next(config.SelectedGroups);

            this.ShowDayBalance = config.ShowDayBalance;

            if (this.calendarComponent) {
                this.calendarComponent.getApi().changeView(config.SelectedView ?? 'resourceTimelineDay');
                this.setCurrentViewState();
            } else {
                setTimeout(() => {
                    this.calendarComponent.getApi().changeView(config.SelectedView ?? 'resourceTimelineDay');
                    this.setCurrentViewState();
                }, 100);
            }
        });

        route.firstChild?.paramMap.pipe(take(1)).subscribe((params) => {
            const date = moment(params.get('date'));
            if (date.isValid()) {
                if (this.calendarComponent) {
                    this.calendarComponent.getApi().gotoDate(date.toDate());
                } else {
                    setTimeout(() => {
                        this.calendarComponent.getApi().gotoDate(date.toDate());
                    }, 100);
                }
            }
        });

        this.workflowGroups$ = workflowService.getWorkflowGroups();
        this.workflows$ = combineLatest([this.store.select(getPlanCalendar), this.selectedGroups$.asObservable()]).pipe(
            map(([res, selectedGroups]) => {
                return res.filter((d) => (selectedGroups ?? []).includes(d.workflow_group));
            }),
            tap((res) => {
                this.CalendarOptions.resources = res.map(
                    (g) => ({ id: `${ g.employee_id }`, title: g.employee_name, info: g.info } as ResourceInput)
                );
                this.CalendarOptions.events = this.fetchEvents.bind(this);
            })
        );

        this.selectedGroups$.pipe(withLatestFrom(appConfig.personalAccountsConfig$)).subscribe(([selected, config]) => {
            if (JSON.stringify(selected.sort()) !== JSON.stringify(config.SelectedGroups.sort())) {
                config.SelectedGroups = selected;
                this.SelectedGroups = selected;
                this.appConfig.setPersonalAccountsConfig(config);
            }
        });
    }

    onDateSelected(event: MatDatepickerInputEvent<Date>) {
        const selectedDate = moment(event.value);
        this.calendarComponent.getApi().gotoDate(selectedDate.toDate());
        this.router.navigateByUrl(
            `/personaloffice/personalkonten/${selectedDate.format('YYYY-MM-DD')}`
        );
        this.datePicker.close();
    }

    eventClick(eventClickInfo) {
        const { event, el, jsEvent, view } = eventClickInfo;
        const calEvent = event;
        // Do not open the dialog on non clickable events
        if (!calEvent.extendedProps.clickable && calEvent.extendedProps.type === 'FZ') {
            return;
        }
        this.listId = calEvent._def.resourceIds[0];
        this.teamLeaderFor = parseInt(this.listId);

        switch (calEvent.extendedProps.type) {
            case 'FZ':
                if ((this.session.team_leader === 1 && this.teamLeaderFor) || (this.session.calendar_pze > 0)) {
                    this.openDialog(TxSchedulerFzDialogComponent, {
                        id: calEvent.id,
                        type: calEvent.extendedProps.fehlzeit_id,
                        start: moment(calEvent.extendedProps.datum ?? calEvent.start).format('YYYY-MM-DD'),
                        end: moment(calEvent.extendedProps.datum ?? calEvent.start).format('YYYY-MM-DD'),
                        note: null,
                        teamLeaderFor: !!this.teamLeaderFor
                    } as TxSchedulerFzDialogData);
                }
                break;
            default:
                if (
                    (calEvent.extendedProps.type.toLowerCase() === 'pze' && this.session.calendar_pze > 0)
                ) {
                    this.openDialog(TxSelectTypeDialogComponent, {
                        type: calEvent.extendedProps.type.toLowerCase() as 'select' | 'bde' | 'pze',
                        id: +(calEvent.extendedProps.bde_id ?? (calEvent.extendedProps.beginn_id ?? calEvent.extendedProps.ende_id)),
                        mustCreateWorkflow: [this.session.calendar_pze === 2, this.session.calendar_bde === 2],
                        start: moment(calEvent.extendedProps.beginn as string, 'YYYY-MM-DDTHH:mm:ss'),
                        end: moment(calEvent.extendedProps.ende as string, 'YYYY-MM-DDTHH:mm:ss'),
                        timemodel_id: calEvent.extendedProps.timemodel_id,
                        note: calEvent.extendedProps.notiz ?? '',
                        kst_number: +calEvent.extendedProps.kst_nummer,
                        auftrag_nummer: calEvent.extendedProps.auftrag_nummer,
                        schritt_nummer: calEvent.extendedProps.schritt_nummer,
                        quantity: +calEvent.extendedProps.quantity,
                        teamLeaderFor: !!this.teamLeaderFor
                    });
                } else if (
                    (this.session.team_leader === 1 && this.teamLeaderFor) ||
                    (calEvent.extendedProps.type.toLowerCase() === 'bde' && this.session.calendar_bde > 0)) {
                    this.openDialog(TxSelectTypeDialogComponent, {
                        type: calEvent.extendedProps.type.toLowerCase() as 'select' | 'bde' | 'pze',
                        id: +(calEvent.extendedProps.bde_id ?? (calEvent.extendedProps.beginn_id ?? calEvent.extendedProps.ende_id)),
                        mustCreateWorkflow: [this.session.calendar_pze === 2, this.session.calendar_bde === 2],
                        start: moment(calEvent.extendedProps.beginn as string, 'YYYY-MM-DDTHH:mm:ss'),
                        end: moment(calEvent.extendedProps.ende as string, 'YYYY-MM-DDTHH:mm:ss'),
                        timemodel_id: calEvent.extendedProps.timemodel_id,
                        note: calEvent.extendedProps.note ?? calEvent.extendedProps.info,
                        kst_number: +calEvent.extendedProps.kst_nummer,
                        auftrag_nummer: calEvent.extendedProps.auftrag_nummer,
                        schritt_nummer: calEvent.extendedProps.schritt_nummer,
                        quantity: +calEvent.extendedProps.quantity,
                        teamLeaderFor: !!this.teamLeaderFor
                    });
                }
                break;
        }
    }

    getBookProtocol(date): Promise<any> {
        return new Promise((resolve, reject) => {
            let apiAnfrage: Observable<any>;

            apiAnfrage = this.api.callAPI('getBookProtocol', {
                bookDate: date,
                team_leader_for: this.teamLeaderFor,
            });

            apiAnfrage.subscribe({
                next: (myBookProtocol) => {
                    this.bookProtocol = [];
                    this.bookProtocol = myBookProtocol.ListOfBookProtocol.map((data) => {
                        const myBookDate = moment(data.BookDate);
                        return {
                            Personalnummer: data.Personalnummer,
                            BookDate: myBookDate.format('DD.MM.YYYY'),
                            BookTime: myBookDate.format('H:mm'),
                            BookType: data.BookType,
                            BookError: data.BookError,
                        };
                    });
                    resolve(this.bookProtocol);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    private async openDialog(component: ComponentType<
        TxSelectTypeDialogComponent |
        TxSchedulerFzDialogComponent>, data: TxSelectTypeDialogData | TxSchedulerFzDialogData = null
    ) {
        const ids = [...this.MultiSelectUserIds];

        this.bookProtocol = await this.getBookProtocol(moment(data.start).format('YYYY-MM-DD') + 'T00:00:00');

        const dialogData = {
            ...data,  // Include existing data if any
            bookProtocol: this.bookProtocol  // Pass the bookProtocol data
        };
        const dialogRef = this.dialog.open(component, {
            /*width: '840px',
            minWidth: '320px',
            disableClose: true,*/
            autoFocus: true,
            data: dialogData
        });

        const result: {
            type: 'bde' | 'pze' | 'fz',
            data: TxSchedulerFzDialogResult | TxSchedulerPzeResult | TxSchedulerBdeResult
        } = await lastValueFrom(dialogRef.afterClosed());

        console.log('Dialog geschlossen, Ergebnis:', result, data);

        if (result) {
            if (data.id) {
                await this.updateEvent(parseInt(this.listId), result.type, result, data);
            } else {
                if (!ids.includes(this.data.resource.id)) {
                    ids.push(this.data.resource.id);
                }
                for (const id of ids) {
                    await this.createEvent(+id, result.type, result);
                }
            }
        }
        this.listId = '';
        this.MultiSelectUserIds = [];
        (document.querySelector('.multi-user-select-all-cb') as HTMLInputElement).checked = false;
        document.querySelectorAll('.multi-user-select-cb').forEach((el: HTMLInputElement) => {
            el.removeAttribute('checked');
            el.checked = false;
        });
        this.calendarComponent.getApi().refetchEvents();
    };


    async openFzeDialog(): Promise<void> {
        this.teamLeaderFor = parseInt(this.currentData.resource.id);
        this.isButtonVisible = false;

        const mStart = moment(this.currentData.start);
        const mEnd = moment(this.currentData.end);

        this.openDialog(TxSchedulerFzDialogComponent, {
            id: null,
            type: null,
            start: mStart.format('YYYY-MM-DD'),
            end: (mEnd.format('YYYY-MM-DD') === mStart.format('YYYY-MM-DD') ? mEnd : mEnd.subtract(1, 'day')).format(
                'YYYY-MM-DD'
            ),
            note: null,
            teamLeaderFor: !!this.teamLeaderFor || false
        } as TxSchedulerFzDialogData);
    }

    async openTypeDialog(): Promise<void> {
        // const ids = [...this.MultiSelectUserIds];
        this.teamLeaderFor = parseInt(this.currentData.resource.id);
        this.isButtonVisible = false;
        const mStart = moment(this.currentData.start);
        const mEnd = moment(this.currentData.end)

        let auftrag_nummer: string | null = this.localStorageService.get<string | null>('bdeAuftragNummer');


        this.openDialog(TxSelectTypeDialogComponent, {
            type: 'select',
            id: null,
            mustCreateWorkflow: [this.session.calendar_pze === 2, this.session.calendar_bde === 2],
            start: mStart,
            end: mEnd,
            timemodel_id: null,
            note: null,
            kst_number: null,
            auftrag_nummer: auftrag_nummer,
            schritt_nummer: null,
            quantity: 0,
            teamLeaderFor: !!this.teamLeaderFor || false
        });
    }

    async selectDateRange(info: DateSelectArg) {
        const ids = [...this.MultiSelectUserIds];

        if (!ids.includes(info.resource.id)) {
            ids.push(info.resource.id);
        }
        this.currentData = info;

        const mStart = moment(info?.start);
        const mEnd = moment(info?.end);
        const endDate = (mEnd.format('YYYY-MM-DD') === mStart.format('YYYY-MM-DD') ? mEnd.format('YYYY-MM-DD') : mEnd.subtract(1, 'day').format('YYYY-MM-DD'));

        if (ids.length <= 1 && mStart.format('YYYY-MM-DD') === endDate) {

            this.mouseX = info.jsEvent.clientX - 240;
            this.mouseY = info.jsEvent.clientY - 90;

            if (info?.start.getTime() == this.data?.start.getTime() && info?.end.getTime() == this.data?.end.getTime()) {
                if (this.data.resource.id === info.resource.id) {
                    if (this.data.view.type === info.view.type) {
                        this.isButtonVisible = !this.isButtonVisible;
                    } else {
                        this.isButtonVisible = true;

                    }
                } else {
                    this.isButtonVisible = true;
                }
            } else {
                this.isButtonVisible = true;
            }
        } else {
            this.isButtonVisible = false;
            this.openFzeDialog();
        }

        this.data = info;
    }

    async updateEvent(employee_id: number, type: 'bde' | 'pze' | 'fz', result, oldData) {
        const data = new TxUpdateBooking();

        let res: ApiResponseType = {
            statuscode: -1,
            statustext: 'Es konnte keine Abfrage gesendet werden.',
            hash: '',
        };


        switch (type) {
            case 'fz':
                const fzData = result.data as TxSchedulerFzDialogResult;

                data.book_beginn = `${ fzData.startDate }T00:00:00`;
                data.book_end = `${ fzData.endDate }T23:59:59`;
                data.book_beginn_id = +(fzData.id.replace('FZ', ''));
                data.book_end_id = 0;
                data.info = fzData.note;
                data.book_type = type.toUpperCase();
                data.team_leader_for = employee_id;
                data.fehlzeit_id = fzData.bookingType ? parseInt(fzData.bookingType.nummer, 10) : 0;

                res = await lastValueFrom(this.api.callAPI('updateBooking', data));
                break;
            case 'bde':
                const bdeData = result.data as TxSchedulerBdeResult;

                data.book_beginn =
                    bdeData.date + 'T' + bdeData.start + ':00';
                data.book_end =
                    bdeData.date + 'T' + bdeData.end + ':00';
                data.book_type = type.toUpperCase();
                data.book_beginn_id = oldData.id;
                data.book_end_id = 0;
                data.auftrag_nummer = bdeData.auftrag_nummer;
                data.schritt_nummer = bdeData.schritt_nummer;
                data.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                data.info = bdeData.note;
                data.quantity = +bdeData.quantity;

                this.localStorageService.set('bdeAuftragNummer', bdeData.auftrag_nummer);

                res = await lastValueFrom(this.api.callAPI('updateBooking', data));
                break;
            case 'pze':
                const pzeData = result.data as TxSchedulerPzeResult;

                data.book_beginn =
                    pzeData.date + 'T' + pzeData.start + ':00';
                data.book_end =
                    pzeData.date + 'T' + pzeData.end + ':00';

                data.book_type = type.toUpperCase();
                data.book_beginn_id = oldData.id;
                data.book_end_id = oldData.id;
                data.kst_nummer = pzeData.kst_number;
                data.timemodel_id = pzeData.timemodel_id;
                data.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                data.info = pzeData.note ? pzeData.note : '';

                res = await lastValueFrom(
                    this.api.callAPI('updateBooking', data).pipe(
                        delay(1000)
                    )
                );
                break;
            default:
                break;
        }

        if (res.statuscode !== 0) {
            Messenger().post({
                message: res.statustext,
                type: 'error',
                showCloseButton: true,
            });
        } else {
            Messenger().post({
                message: res.statustext,
                type: 'success',
                showCloseButton: true,
                hideAfter: 5,
            });
        }
    }

    async createEvent(employee_id: number, type: 'bde' | 'pze' | 'fz', result) {
        const data = new TxCreateBooking();

        const mStart = moment(new Date(result.data.startDate));
        const mEnd = moment(new Date(result.data.endDate));

        let res: ApiResponseType = {
            statuscode: -1,
            statustext: 'Es konnte keine Abfrage gesendet werden.',
            hash: '',
        };
        switch (type) {
            case 'fz':
                data.book_beginn = mStart.format('YYYY-MM-DD') + 'T00:00:00';
                data.book_end = mEnd.format(
                    'YYYY-MM-DD'
                ) + 'T23:59:59';
                data.book_type = type.toUpperCase();
                data.team_leader_for = employee_id;
                data.info = result.data.note;
                data.fehlzeit_id = +result.data.bookingType.nummer;
                res = await lastValueFrom(this.api.callAPI('setBookPeriod', data));

                break;
            case 'bde':
                const bdeData = result.data as TxSchedulerBdeResult;

                data.book_beginn = bdeData.date + 'T' + bdeData.start + ':00';
                data.book_end = bdeData.date + 'T' + bdeData.end + ':00';
                data.book_type = type.toUpperCase();
                data.auftrag_nummer = bdeData.auftrag_nummer;
                data.schritt_nummer = bdeData.schritt_nummer;
                data.team_leader_for = employee_id;
                data.info = bdeData.note;
                data.quantity = +bdeData.quantity;
                res = await lastValueFrom(this.api.callAPI('setBookPeriod', data));
                break;
            case 'pze':
                const pzeData = result.data as TxSchedulerPzeResult;

                data.book_beginn =
                    pzeData.date + 'T' + pzeData.start + ':00';
                data.book_end =
                    pzeData.date + 'T' + pzeData.end + ':00';

                data.book_type = type.toUpperCase();
                data.kst_nummer = pzeData.kst_number ?? 0;
                data.timemodel_id = pzeData.timemodel_id ?? 0;
                data.team_leader_for = this.teamLeaderFor ?? 0;
                data.info = pzeData.note ? pzeData.note : '';
                res = await lastValueFrom(this.api.callAPI('setBookPeriod', data));
                break;
            default:
                break;
        }


        if (res.statuscode !== 0) {
            Messenger().post({
                message: res.statustext,
                type: 'error',
                showCloseButton: true,
            });
        } else {
            Messenger().post({
                message: res.statustext,
                type: 'success',
                showCloseButton: true,
                hideAfter: 5,
            });
        }
    }

    toggleMultiSelect(id: string) {
        if (this.MultiSelectUserIds.includes(id)) {
            this.MultiSelectUserIds = this.MultiSelectUserIds.filter((s) => s !== id);
        } else {
            this.MultiSelectUserIds.push(id);
        }
    }

    toggleDayBalance(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();

        this.ShowDayBalance = !this.ShowDayBalance;

        this.appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
            config.ShowDayBalance = this.ShowDayBalance;

            this.appConfig.setPersonalAccountsConfig(config);
        });

        this.calendarComponent.getApi().refetchEvents();

        return false;
    }

    ngAfterViewInit(): void {
        this.cutoffDateLoaded$.pipe(
            filter(loaded => loaded),
            take(1)
        ).subscribe(() => {
            const calendarApi = this.calendarComponent.getApi();

            calendarApi.setOption('selectable', true);

            if (this.cutoffDate) {
                const cutoffMoment = moment(this.cutoffDate).endOf('day');

                calendarApi.setOption('selectConstraint', {
                    start: cutoffMoment.startOf('day').format('YYYY-MM-DD')
                });

                calendarApi.setOption('selectAllow', (selectInfo) => {
                    return moment(selectInfo.start).isAfter(cutoffMoment);
                });

                calendarApi.setOption('dateClick', (dateClickInfo) => {
                    if (moment(dateClickInfo.date).isSameOrBefore(cutoffMoment)) {
                        Messenger().post({
                            message: this.translate.instant('txScheduler.blockedDateMessage'),
                            type: 'error',
                            showCloseButton: true,
                        });
                    }
                });
            }
    });
    }

    setCurrentViewState(): void {
        // get current view type
        const viewType = this.calendarComponent.getApi().view.type;
        // change state of view type button, depending on which view is currently active
        if (viewType === 'resourceTimelineDay') {
            document.querySelector('.fc-viewDay-button').classList.add('fc-state-active');
        } else if (viewType === 'resourceTimelineWeek') {
            document.querySelector('.fc-viewWeek-button').classList.add('fc-state-active');
        }
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
        this.myTxApiService.callAPI('getSetting', { settingName: 'BLOCK_DATE' }).subscribe(
            (response) => {
                this.cutoffDate = response.settingString;
                this.cutoffDateLoaded$.next(true);
            },
            (error) => {
                console.log('ERROR' + error);
                this.cutoffDateLoaded$.next(true);
            }
        );
        this.loadKSTs();
        this.loadTimemodels();
        this.loadContracts();
        this.renderer.listen('document', 'click', (event) => {
            const clickedElement = event.target as HTMLElement;

            if (!clickedElement.classList.contains('fc-timeline-body')) {
                this.isButtonVisible = false;
            }
        });
    }

    loadContracts(): void {
        this.Contracts$ = this.dataService.getContracts();
        this.Contracts$.subscribe();
    }

    loadKSTs(): void {
        this.BdeKst$ = this.dataService.getKSTs();
        this.BdeKst$.subscribe();
    }

    loadTimemodels(): void {
        this.timemodels$ = this.personalOfficeService.getTimemodelList();
        this.timemodels$.subscribe();
    }

    toggleGroup(event: MatButtonToggleChange) {
        this.selectedGroups$.next(event.value);
    }

    fetchEvents(info, success, failure) {
        // Use a single timeout to prevent multiple rapid calls
        if (this.fetchEventsTimeout) {
            clearTimeout(this.fetchEventsTimeout);
        }

        this.fetchEventsTimeout = setTimeout(() => {
            const calendarApi = this.calendarComponent.getApi();
            const schedulerStartDate = moment(calendarApi.view.currentStart).format('YYYY-MM-DD') + 'T00:00:00';
            const schedulerEndDate = moment(calendarApi.view.currentEnd).format('YYYY-MM-DD') + 'T00:00:00';

            // Get filtered employees once
            this.store.select(getPlanCalendar)
                .pipe(
                    take(1),
                    map(res => res.filter(d => this.SelectedGroups.includes(d.workflow_group))),
                    tap(res => {
                        // Update account infos once per employee
                        res.forEach(r => {
                            this.accountInfos$.next({
                                teamleader_for: r.employee_id,
                                begin_date: calendarApi.view.currentStart,
                                end_date: moment(calendarApi.view.currentEnd).subtract(1, 'day').toDate(),
                            });
                        });
                    }),
                    switchMap(employees => {
                        // Create a single batch of API requests for all employees
                        const apiRequests = employees.map(employee => {
                            const params = {
                                von: schedulerStartDate,
                                bis: schedulerEndDate,
                                team_leader_for: employee.employee_id,
                                staff_office: 1,
                            };

                            // Make parallel requests for each data type
                            return forkJoin({
                                head: this.api.callAPI('getCalendarHead', params),
                                pze: this.api.callAPI('getCalendarPZE', params),
                                bde: this.api.callAPI('getCalendarBDE', params)
                            }).pipe(
                                map(data => this.processEmployeeData(data, employee))
                            );
                        });

                        // Combine all employee data
                        return forkJoin(apiRequests);
                    }),
                    map(employeeEvents => {
                        // Flatten all events and format them consistently
                        const events = employeeEvents.flat();
                        return events.map(el => {
                            const color = chroma(el.backgroundColor || el.color);
                            return {
                                id: el.id,
                                resourceId: el.resourceId,
                                allDay: el.allDay,
                                title: el.title.includes('<') ? '' : el.title,
                                start: el.start,
                                end: el.end,
                                timeZone: 'local',
                                classNames: 'event-round-border ' + (el.classNames ?? ''),
                                backgroundColor: el.backgroundColor || el.color,
                                borderColor: el.borderColor ?? color.brighten(0.5).hex(),
                                textColor: color.luminance() > 0.5 ? 'black' : 'white',
                                extendedProps: {
                                    ...el.extendedProps,
                                    info: el.title.includes('<') ? el.title : null,
                                },
                            };
                        });
                    })
                )
                .subscribe({
                    next: (formattedEvents) => {
                        success(formattedEvents);
                    },
                    error: (error) => {
                        failure(error);
                    }
                });
        }, 300); // Debounce time
    }

// Helper method to process employee data
    private processEmployeeData(data: { head: any, pze: any, bde: any }, employee: any): EventInput[] {
        const events: EventInput[] = [];

        // Process Head events
        const headEvents = data.head.ListOfCalendarHead
            .filter(schedulerData => {
                if (!schedulerData.fehlzeit_name && !schedulerData.fehlzeit_id && !schedulerData.info) {
                    return false;
                }
                return this.ShowDayBalance || schedulerData.kto_show !== 0;
            })
            .map(schedulerData => {
                const datum = moment(schedulerData.datum).format('YYYY-MM-DD');
                const title = (schedulerData.info ?? '');
                let color = schedulerData.fehlzeit_color ? schedulerData.fehlzeit_color : 'rgba(210,210,210,1)';
                color = `rgba(${ chroma(color).rgb().join(',') }, 0.4)`;

                return {
                    id: schedulerData.id + 'FZ',
                    resourceId: employee.employee_id,
                    title: title?.replace('null', '') ?? '',
                    start: datum,
                    end: datum,
                    color,
                    classNames: !!schedulerData.fehlzeit_id ? 'event-absence' : 'event-summary',
                    allDay: true,
                    extendedProps: {
                        type: 'FZ',
                        fehlzeit_id: schedulerData.fehlzeit_id,
                        fehlzeit_dauer: schedulerData.fehlzeit_dauer,
                        fehlzeit_name: schedulerData.fehlzeit_name,
                        clickable: !!schedulerData.fehlzeit_name,
                        tooltip: schedulerData.tooltip,
                    }
                };
            });

        // Process PZE events
        const pzeEvents = data.pze.ListOfCalendarPZE.map(schedulerData => {
            const color = chroma(schedulerData.color);
            return {
                id: schedulerData.id?.toString() ?? '',
                resourceId: employee.employee_id,
                title: 'PZE',
                start: moment(schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende).toDate(),
                end: moment(schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn).toDate(),
                backgroundColor: schedulerData.color,
                borderColor: color.brighten(0.5).hex(),
                textColor: color.luminance() > 0.5 ? 'black' : 'white',
                classNames: 'pze-event',
                allDay: false,
                overlap: false,
                extendedProps: {
                    type: 'PZE',
                    datum: schedulerData.datum,
                    info: schedulerData.info,
                    notiz: schedulerData.notiz,
                    beginn_id: schedulerData.beginn_id,
                    beginn: schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende,
                    ende_id: schedulerData.ende_id,
                    ende: schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn,
                    kst_nummer: schedulerData.kst_nummer,
                }
            };
        });

        // Process BDE events
        const bdeEvents = data.bde.ListOfCalendarBDE.map(schedulerData => {
            const color = chroma(schedulerData.color);
            return {
                id: schedulerData.id + '',
                resourceId: employee.employee_id,
                title: schedulerData.schritt_name
                    ? schedulerData.auftrag_name + ' – ' + schedulerData.schritt_name
                    : schedulerData.auftrag_name,
                start: schedulerData.beginn,
                end: schedulerData.ende,
                backgroundColor: schedulerData.color,
                borderColor: color.brighten(0.5).hex(),
                textColor: color.luminance() > 0.5 ? 'black' : 'white',
                classNames: '',
                overlap: false,
                allDay: false,
                extendedProps: {
                    type: 'BDE',
                    datum: schedulerData.datum,
                    beginn: schedulerData.beginn,
                    ende: schedulerData.ende,
                    auftrag_nummer: schedulerData.auftrag_nummer,
                    schritt_nummer: schedulerData.schritt_nummer,
                    bde_id: schedulerData.id,
                    note: schedulerData.info,
                    quantity: schedulerData.quantity,
                }
            };
        });

        return [...headEvents, ...pzeEvents, ...bdeEvents];
    }

}
