import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import 'twix';
import { TxSession } from '../../../shared/interfaces/session';
import { AuthService } from '../../../shared/services/auth.service';
import {
    Observable,
    Subscription
} from 'rxjs';
import { TxBdeKST } from '../../../shared/interfaces/buchungen';
import {
    FormBuilder,
    Validators
} from '@angular/forms';
import { getTimemodels } from '../../../store/selectors/personal-office.selectors';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getCostcenters } from '../../../store/selectors/data.selectors';
import moment from 'moment';
import { OnInit } from '@angular/core';
import {
    TxSchedulerBdeResult,
    TxSchedulerPzeResult,
    TxSchedulerSelectFormResult
} from '../dialogs/tx-select-type-dialog/tx-select-type.dialog.component';
import { TxApiService } from '../../../shared/services/txapi.service';

@Component({
    selector: 'app-tx-scheduler-pze',
    templateUrl: './tx-scheduler-pze.component.html'
})
export class TxSchedulerPzeComponent implements OnInit, OnDestroy {
    minDate: Date | null = null;
    cutoffDate: string | null = null;
    @Input() date: string = moment().format('YYYY-MM-DD');
    @Input() startTime = '08:00';
    @Input() endTime = '16:30';

    @Input() ngModel: TxSchedulerSelectFormResult<TxSchedulerPzeResult>;
    @Output() ngModelChange = new EventEmitter<TxSchedulerSelectFormResult<TxSchedulerPzeResult>>();

    private formSubscription: Subscription;

    public Session: TxSession;
    teamLeaderFor: number;
    title: string;
    public Timemodels$: Observable<{ label: string, value: number }[]>;
    public BdeKst$: Observable<TxBdeKST[]>;
    public pzeForm = this.formBuilder.group({
        date: [null, Validators.required],
        start: [null, Validators.required],
        end: [null, Validators.required],
        kst_number: [null],
        timemodel_id: [null],
        note: [null]
    });
    id: string | null = null;

    constructor(
        public readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private myTxApiService: TxApiService,
        private readonly store: Store,
    ) {
        this.myTxApiService.callAPI('getSetting', { settingName: 'BLOCK_DATE' }).subscribe(
            (response) => {
                if (response && response.settingString) {
                    this.cutoffDate = response.settingString;
                    const [year, month, day] = this.cutoffDate.split('-').map(Number);
                    this.minDate = new Date(year, month - 1, day + 1);
                } else {
                    this.minDate = null;
                }
            },
            (error) => {
                console.error('Fehler beim Laden des BLOCK_DATE:', error);
                this.minDate = null;
            }
        );
        this.Timemodels$ = this.store.select(getTimemodels).pipe(
            map((timemodels) => timemodels.map(t => ({
                value: t.model_id,
                label: t.model_name
            })))
        );
        this.BdeKst$ = this.store.select(getCostcenters).pipe(
            map((costcenters) => costcenters.map(kst =>
                new TxBdeKST(+kst.nummer, kst.bezeichnung)))
        );

        this.Session = authService.getSession();

        this.formSubscription = this.pzeForm.valueChanges.subscribe((value) => {
            this.ngModelChange.emit({
                isValid: this.pzeForm.valid,
                value: {
                    ...this.ngModel.value,
                    ...value
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }
    ngOnInit() {
        this.toggleFieldBasedOnId();
        this.pzeForm.patchValue({
            date: this.date,
            start: this.startTime,
            end: this.endTime,
            kst_number: this.ngModel.value.kst_number,
            timemodel_id: this.ngModel.value.timemodel_id,
            note:  this.stripHtmlTags(this.ngModel.value.note)
        });
    }

    toggleFieldBasedOnId(): void {
        const currentUrl = window.location.hash;

        const urlPart = currentUrl.split('#')[1] || '';

        const parts = urlPart.split(';');

        for (const part of parts) {
            if (part.startsWith('id=')) {
                this.id = part.split('=')[1];
                break;
            }
        }
        if (urlPart === '/personaloffice/personalkonten'){
            this.pzeForm.get('timemodel_id')?.enable();
        } else {
            if (this.id) {
                this.pzeForm.get('timemodel_id')?.enable();
            } else {
                this.pzeForm.get('timemodel_id')?.disable();
            }
        }
    }

    stripHtmlTags(value: string): string {
        return value ? value.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '') : value;
    }
}
