import {
    AfterViewChecked,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import moment from 'moment';
import * as $ from 'jquery';
import * as twix from 'twix';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ListOfWorkflowGroup, WorkflowCalendarData } from '../../shared/interfaces/workflow';
import { AuthService } from '../../shared/services/auth.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { debounceTime, map } from 'rxjs/operators';
import { TxApiService } from '../../shared/services/txapi.service';

declare let jQuery: any;
interface SpecialDay {
    datum: string;
    special_day: string;
    fehlzeit_name?: string;
    info?: string;
}

@Component({
	selector: 'tx-workflow-calendar',
	templateUrl: './tx-workflow-calendar.component.html',
	styleUrls: ['./tx-workflow-calendar.component.scss'],
})


export class TxWorkflowCalendarComponent implements OnInit, AfterViewChecked, OnDestroy {
	@Input() set CalendarData(value: any) {
		if (typeof this.myCalendarData !== 'undefined') {
			this.updateComponent(value);
		}
		if (value === null) {
			this.calendarReady = false;
		}
		this.myCalendarData = value;
	}

	get CalendarData(): any {
		return this.myCalendarData;
	}

	@Input()
	get WfGroups(): Array<ListOfWorkflowGroup> {
		return this._wfGroups;
	}
	set WfGroups(data: Array<ListOfWorkflowGroup>) {
		this._wfGroups = data;
		this.syncGroupStates();
	}

	constructor(
		private mySession: AuthService,
		private translate: TranslateService,
        private myTxApiService: TxApiService,
		private localStorageService: LocalStorageService
	) {
		this.currentPersoId = this.mySession.getSession().personalnr;
		this.defaultLocale = this.translate.currentLang;

		this.scrollDebounce$
			.asObservable()
			.pipe(
				debounceTime(200),
				map((scrollLeft: number) => {
					this.localStorageService.set('txWorkflowCalendarScrollLeft', scrollLeft);
				})
			)
			.subscribe();
	}

	get componentState() {
		if (this.WfGroups) {
			return this.componentChecks.data && this.componentChecks.storage && this.componentChecks.view;
		} else {
			return this.componentChecks.data && this.componentChecks.view;
		}
	}
	private myCalendarData: any;

	@Input() showAllDataLoad = false;

	@Input() TeamLeader = false;
	private _wfGroups: ListOfWorkflowGroup[] = null;

	@Output() OnClickLoadFullYear: Subject<null> = new Subject<null>();
	@Output() OnBusy: Subject<boolean> = new Subject<boolean>();
	@Output() OnRefresh: Subject<null> = new Subject<null>();

	pagination = 1;
	itemsPerPage = 10;
	myCalendarRange: WorkflowCalendarData = { days: [], daysInCurrentYear: [], month: [], years: [], persons: [] };
	theDefaultDayArray = [];
	myCalendarHasData = false;
	groupFilterTrigger = true;
	ngAfterViewCheckedDone = false;
	componentChecks = {
		data: false,
		view: false,
		storage: false,
	};
	calendarReady = false;
	currentPersoId: any;
	defaultLocale = 'de-DE';

	activeWfGroups: number[];

	private scrollDebounce$ = new Subject();
    private specialDays: SpecialDay[] = [];
    private specialDayCache: Map<string, boolean> = new Map();

	checkCurrentUser(aPersonalnr: string): boolean {
		const myCurrentUser = JSON.parse(localStorage.getItem('currentSession'));
		return aPersonalnr === myCurrentUser.personalnr;
	}

    formatDate(days: any[]): string {
        return `${days[3]}-${days[2]}-${days[0]}`;
    }

    clearSpecialDayCache() {
        this.specialDayCache.clear();
    }

    isSpecialDay(dateStr: string): boolean {
        if (this.specialDayCache.has(dateStr)) {
            return this.specialDayCache.get(dateStr);
        }

        const formattedDate = moment(dateStr).format('YYYY-MM-DD');
        const result = this.specialDays.some(day =>
            moment(day.datum).format('YYYY-MM-DD') === formattedDate
        );

        this.specialDayCache.set(dateStr, result);
        return result;
    }

    precalculateSpecialDays() {
        if (!this.myCalendarRange?.days) return;

        this.clearSpecialDayCache();
        this.myCalendarRange.days.forEach(days => {
            const dateStr = this.formatDate(days);
            this.isSpecialDay(dateStr);
        });
    }

    loadSpecialDays() {
        if (!this.CalendarData || this.CalendarData.length === 0) return;

        const startDate = moment(this.CalendarData[0].begin_date).format('YYYY-MM-DDTHH:mm:ss');
        const endDate = moment(this.CalendarData[0].end_date).format('YYYY-MM-DDTHH:mm:ss');

        const schedulerDateRange = {
            von: startDate,
            bis: endDate,
            team_leader_for: this.TeamLeader ? 1 : 0
        };

        this.myTxApiService.callAPI('getCalendarHead', schedulerDateRange).pipe(
            map((response: any) => {
                if (response && response.ListOfCalendarHead) {
                    return response.ListOfCalendarHead
                        .filter(item => item.special_day !== null)
                        .map(item => ({
                            datum: item.datum,
                            special_day: item.special_day,
                            fehlzeit_name: item.fehlzeit_name,
                            info: item.info
                        }));
                }
                return [];
            })
        ).subscribe({
            next: (specialDays) => {
                this.specialDays = specialDays;
                this.precalculateSpecialDays();
            },
            error: (error) => {
                console.error('Fehler beim Laden der speziellen Tage:', error);
                this.specialDays = []; // Setze auf leeres Array im Fehlerfall
            }
        });
    }

	isTeamLeader(): boolean {
		const myCurrentUser = JSON.parse(localStorage.getItem('currentSession'));
		return myCurrentUser.team_leader === 1;
	}

	toggleGroup(event, id) {
		this.OnBusy.next(true);
		const groupToChange = this.WfGroups.findIndex((data) => data.id === id);
		this.WfGroups[groupToChange].active = !this.WfGroups[groupToChange].active;
		this.groupFilterTrigger = !this.groupFilterTrigger;
		this.localStorageService.set('txWorkflowGroups', this.WfGroups);

		setTimeout(() => {
			this.OnBusy.next(false);
		}, 500);
	}

	syncGroupStates() {
		const items = this.localStorageService.get('txWorkflowGroups') ?? [];
		if (Array.isArray(this.WfGroups)) {
			if (items) {
				this.WfGroups.forEach((group) => {
					const lsItem = items.find((ls) => ls.id === group.id);
					if (lsItem) {
						group.active = lsItem.active;
					}
				});
			} else {
				this.WfGroups.forEach((group) => (group.active = true));
			}
			this.groupFilterTrigger = !this.groupFilterTrigger;
			this.componentChecks.storage = true;
		}
	}

	createCalendarView() {
		let curEl;
		let daysInMonthWanted;

		this.componentChecks.data = true;
		if (this.CalendarData.length > 0) {
			this.myCalendarHasData = true;

			const myTwix = (moment as any).twix(
				new Date(this.CalendarData[0].begin_date),
				new Date(this.CalendarData[0].end_date)
			);
			const beginDate = moment(this.CalendarData[0].begin_date, 'YYYY-MM-DDTHH:mm:ss');

			moment.locale(this.defaultLocale.slice(0, 2));

			let itr = myTwix.iterate('years');
			while (itr.hasNext()) {
				this.myCalendarRange.years.push(itr.next().format('YYYY'));
			}

			this.myCalendarRange.years.forEach((dataEntry) => {
				const beginYearDate = String(beginDate.year());
				if (dataEntry === beginYearDate) {
					this.myCalendarRange.daysInCurrentYear.push([
						(moment as any).twix(beginDate, moment(beginDate.year() + '-12-31', 'YYYY-MM-DD')).count('days'),
						dataEntry,
					]);
				} else {
					const restOfNextYear =
						(moment as any)
							.twix(new Date(this.CalendarData[0].begin_date), new Date(this.CalendarData[0].end_date))
							.count('days') -
						(moment as any).twix(beginDate, moment(beginDate.year() + '-12-31', 'YYYY-MM-DD')).count('days');
					this.myCalendarRange.daysInCurrentYear.push([restOfNextYear, dataEntry]);
				}
			});

			itr = myTwix.iterate('month');
			while (itr.hasNext()) {
				curEl = itr.next();
				curEl.locale(this.defaultLocale);
				daysInMonthWanted = (moment(curEl) as any)
					.twix(moment(curEl).add(curEl.daysInMonth() - 1, 'days'))
					.intersection(myTwix)
					.count('days');

				this.myCalendarRange.month.push(Array(curEl.format('MMMM'), curEl.format('YYYY'), daysInMonthWanted));
			}

			itr = myTwix.iterate('days');

			while (itr.hasNext()) {
				curEl = itr.next();
				curEl.locale('this.defaultLocale');
				const today = moment(curEl).isSame(moment(), 'day');
				this.myCalendarRange.days.push(
					Array(curEl.format('DD'), curEl.format('dd'), curEl.format('MM'), curEl.format('YYYY'), today)
				);
			}

			for (const defaultDay of this.myCalendarRange.days) {
				const currentDate = defaultDay[3] + '-' + defaultDay[2] + '-' + defaultDay[0] + 'T00:00:00';
				if (moment(currentDate).isSame(moment(), 'day')) {
					if (moment(currentDate).isoWeekday() > 5) {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '#ddd',
							table_text: '',
							todayClass: 'today',
							isError: '',
						});
					} else {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '',
							table_text: '',
							todayClass: 'today',
							isError: '',
						});
					}
				} else {
					if (moment(currentDate).isoWeekday() > 5) {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '#ddd',
							table_text: '',
							todayClass: '',
							isError: '',
						});
					} else {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '',
							table_text: '',
							todayClass: '',
							isError: '',
						});
					}
				}
			}

			const teamForLocalStorage = [];

			for (const aPersonEntry of this.CalendarData) {
				const aPersonsCalendarData = this.theDefaultDayArray.slice();

				if (aPersonEntry.ListOfDateItem != null) {
					for (const dateitem of aPersonEntry.ListOfDateItem) {
						dateitem.fehlzeit_name = dateitem.fehlzeit_name ? this.decode(dateitem.fehlzeit_name) : '';
						const dateitemOffset = (moment(this.CalendarData[0].begin_date) as any)
							.twix(moment(dateitem.datum))
							.count('days');
						aPersonsCalendarData[dateitemOffset - 1] = dateitem;
					}
				}

				this.myCalendarRange.persons.push({
					personalnr: aPersonEntry.personalnr,
					name: aPersonEntry.name,
					info: aPersonEntry.info,
					data: aPersonsCalendarData,
					group: aPersonEntry.workflow_group,
				});

				teamForLocalStorage.push({
					personalnr: aPersonEntry.personalnr,
					name: aPersonEntry.name,
				});
			}
			localStorage.setItem('txTeam', JSON.stringify(teamForLocalStorage));
		}
		this.componentChecks.view = true;
	}

	trackByFn(index) {
		return index;
	}

	onScrollCalendar(event: Event) {
		if (event.target instanceof HTMLDivElement) {
			this.scrollDebounce$.next(event.target.scrollLeft);
		}
	}

	jumpToToday(force = false) {
		const $scroller = jQuery('.scrollable .table-overflow');
		const scrollLeft = this.localStorageService.get('txWorkflowCalendarScrollLeft') ?? -1;
		if (!force && scrollLeft > -1) {
			$scroller.animate({ scrollLeft: scrollLeft }, 500);
		} else {
			const scrollTo = jQuery('.flatcalendar .scheduletable th.today').position();
			if (typeof scrollTo !== 'undefined' && 'left' in scrollTo) {
				$scroller.animate({ scrollLeft: scrollTo.left }, 500);
			}
		}
	}

	refresh() {
		if (this.OnRefresh) {
			this.OnRefresh.next(null);
		}
	}

	loadFullYear() {
		if (this.OnClickLoadFullYear) {
			this.OnClickLoadFullYear.next(null);
		}
	}

	decode(str) {
		const span = document.createElement('span');
		return str.replace(/&[#A-Za-z0-9]+;/gi, (match, dec) => {
			span.innerHTML = match;
			return span.innerText;
		});
	}

	ngAfterViewChecked() {
		if (!this.ngAfterViewCheckedDone && this.componentState) {
			this.ngAfterViewCheckedDone = true;
			const $tdToday = jQuery('.today');
			const $thToday = $tdToday.closest('table tr.days').find('th').eq($tdToday.index());

			$thToday.addClass('today');

			jQuery('.scrollable .table-overflow').scroll(() => {
				$('.pinned .table-overflow table').css('margin-top', -$('.scrollable .table-overflow').scrollTop());
				jQuery('.calendar-data').css('margin-left', -jQuery('.scrollable .table-overflow').scrollLeft());
			});
			if (!this.calendarReady) {
				setTimeout(() => {
					this.calendarReady = true;
					setTimeout(() => this.jumpToToday());
				}, 250);
			}
		}
	}

	persistItemsPerPage() {
		const localStorageSettingsKey = 'txWorkflowCalendarItemsPerPage' + (this.TeamLeader ? 'Teamleader' : '');
		this.localStorageService.set(localStorageSettingsKey, { itemPerPage: this.itemsPerPage, page: this.pagination });
	}

	ngOnInit() {
		this.syncGroupStates();
		this.createCalendarView();
		const localStorageSettingsKey = 'txWorkflowCalendarItemsPerPage' + (this.TeamLeader ? 'Teamleader' : '');
		const value = this.localStorageService.get(localStorageSettingsKey);
		if (value !== null) {
			this.itemsPerPage = value.itemPerPage;
			this.pagination = value.page;
		}

        this.loadSpecialDays();
	}

	private updateComponent(calendardata: any) {
		let curEl;
		let daysInMonthWanted;

		const updatedCalendar: WorkflowCalendarData = {
			days: [],
			daysInCurrentYear: [],
			month: [],
			years: [],
			persons: [],
		};

		this.componentChecks.data = true;
		if (calendardata.length > 0) {
			this.myCalendarHasData = true;

			const myTwix = moment.twix(moment(calendardata[0].begin_date), moment(calendardata[0].end_date));
			const beginDate = moment(calendardata[0].begin_date, 'YYYY-MM-DDTHH:mm:ss');

			let itr = myTwix.iterate('years');
			while (itr.hasNext()) {
				updatedCalendar.years.push(itr.next().format('YYYY'));
			}

			updatedCalendar.years.forEach((dataEntry) => {
				const beginYearDate = String(beginDate.year());
				if (dataEntry === beginYearDate) {
					updatedCalendar.daysInCurrentYear.push([
						(moment as any).twix(beginDate, moment(beginDate.year() + '-12-31', 'YYYY-MM-DD')).count('days'),
						dataEntry,
					]);
				} else {
					const restOfNextYear =
						(moment as any)
							.twix(new Date(calendardata[0].begin_date), new Date(calendardata[0].end_date))
							.count('days') -
						(moment as any).twix(beginDate, moment(beginDate.year() + '-12-31', 'YYYY-MM-DD')).count('days');
					updatedCalendar.daysInCurrentYear.push([restOfNextYear, dataEntry]);
				}
			});

			itr = myTwix.iterate('month');
			while (itr.hasNext()) {
				curEl = itr.next();
				daysInMonthWanted = (moment(curEl) as any)
					.twix(moment(curEl).add(curEl.daysInMonth() - 1, 'days'))
					.intersection(myTwix)
					.count('days');

				updatedCalendar.month.push(Array(curEl.format('MMMM'), curEl.format('YYYY'), daysInMonthWanted));
			}

			itr = myTwix.iterate('days');

			while (itr.hasNext()) {
				curEl = itr.next();
				const today = moment(curEl).isSame(moment(), 'day');
				updatedCalendar.days.push(
					Array(curEl.format('DD'), curEl.format('dd'), curEl.format('MM'), curEl.format('YYYY'), today)
				);
			}

			for (const defaultDay of updatedCalendar.days) {
				const currentDate = defaultDay[3] + '-' + defaultDay[2] + '-' + defaultDay[0] + 'T00:00:00';
				if (moment(currentDate).isSame(moment(), 'day')) {
					if (moment(currentDate).isoWeekday() > 5) {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '#ddd',
							table_text: '',
							todayClass: 'today',
							isError: '',
						});
					} else {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '',
							table_text: '',
							todayClass: 'today',
							isError: '',
						});
					}
				} else {
					if (moment(currentDate).isoWeekday() > 5) {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '#ddd',
							table_text: '',
							todayClass: '',
							isError: '',
						});
					} else {
						this.theDefaultDayArray.push({
							calendar_id: '',
							tmpOffset: '',
							datum: currentDate,
							fehlzeit_id: '',
							fehlzeit_name: '',
							fehlzeit_color: '',
							table_text: '',
							todayClass: '',
							isError: '',
						});
					}
				}
			}

			const teamForLocalStorage = [];

			for (const aPersonEntry of calendardata) {
				const aPersonsCalendarData = this.theDefaultDayArray.slice();

				if (aPersonEntry.ListOfDateItem != null) {
					for (const dateitem of aPersonEntry.ListOfDateItem) {
						dateitem.fehlzeit_name = dateitem.fehlzeit_name ? this.decode(dateitem.fehlzeit_name) : '';
						const dateitemOffset = (moment(calendardata[0].begin_date) as any)
							.twix(moment(dateitem.datum))
							.count('days');
						aPersonsCalendarData[dateitemOffset - 1] = dateitem;
					}
				}

				updatedCalendar.persons.push({
					personalnr: aPersonEntry.personalnr,
					name: aPersonEntry.name,
					info: aPersonEntry.info,
					data: aPersonsCalendarData,
					group: aPersonEntry.workflow_group,
				});

				teamForLocalStorage.push({
					personalnr: aPersonEntry.personalnr,
					name: aPersonEntry.name,
				});
			}
			localStorage.setItem('txTeam', JSON.stringify(teamForLocalStorage));
		}
		this.myCalendarRange = updatedCalendar;

		if (!this.calendarReady) {
			setTimeout(() => {
				this.calendarReady = true;
				setTimeout(() => this.jumpToToday());
			}, 250);
		}
        if (calendardata && calendardata.length > 0) {
            this.clearSpecialDayCache();
            this.loadSpecialDays();
        }
	}

	ngOnDestroy() {
		this.scrollDebounce$.unsubscribe();
	}
}
