import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { TxBuchung, TxCreateBooking, TxDeleteBooking, TxUpdateBooking } from '../../shared/interfaces/bdebuchung';
import chroma from 'chroma-js';
import { CalendarService } from '../../shared/services/calendar.service';
import {
    BehaviorSubject,
    combineLatest,
    lastValueFrom,
    Observable,
    startWith,
    Subscription,
    switchMap
} from 'rxjs';
import { TxApiService } from '../../shared/services/txapi.service';
import { AuthService } from '../../shared/services/auth.service';
import { TxSession } from '../../shared/interfaces/session';
import { TxBdeKST } from '../../shared/interfaces/buchungen';
import { TxBdeAuftrag, TxBdeAuftragsschritt, TxBDEProtokoll } from '../../shared/interfaces/bde';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TxCreateWorkflowRequest, TxWorkflowTypen, TYPNEU } from '../../shared/interfaces/workflow';
import { FullCalendarComponent, CalendarOptions, EventInput } from '@fullcalendar/angular';
import {
	Event,
	ListOfCalendarBDE,
	ListOfCalendarHead,
	ListOfCalendarPZE,
	SchedulerEvent,
} from '../../shared/interfaces/scheduler';

import 'twix';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MessengerService } from '../../shared/services/messenger.service';
import { PlansService } from '../../shared/services/plans.service';
import {
    debounceTime,
    delay,
    map,
    take,
    withLatestFrom,
    filter
} from 'rxjs/operators';
import PersonalAccountsConfig, { CalendarEntityConfig } from '../../shared/interfaces/PersonalAccountsConfig';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { PrimeNGConfig } from 'primeng/api';
import { TimemodelEntity } from '../../store/entities/TimemodelEntity';
import { Store } from '@ngrx/store';
import { getTimemodels } from '../../store/selectors/personal-office.selectors';
import {
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';
import {
    TxSchedulerBdeResult,
    TxSchedulerPzeResult,
    TxSelectTypeDialogComponent,
    TxSelectTypeDialogData
} from './dialogs/tx-select-type-dialog/tx-select-type.dialog.component';
import { ComponentType } from '@angular/cdk/overlay';
import {
    TxSchedulerFzDialogComponent,
    TxSchedulerFzDialogData,
    TxSchedulerFzDialogResult
} from './dialogs/tx-scheduler-fz/tx-scheduler-fz.dialog.component';
import {
    ApiResponse,
    ApiResponseType
} from '../../store/entities/Api/ApiResponse';
import { DateSelectArg } from '@fullcalendar/common';
import { TxWarningDialogComponent } from './dialogs/tx-warning-dialog/tx-warning.dialog.component';
import { LocalStorageService } from '../../shared/services/local-storage.service';



declare var Messenger: any;

const defaultHourMode = 610;

@Component({
	selector: 'tx-scheduler',
	templateUrl: './tx-scheduler.component.html',
})
export class TxSchedulerComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('fullCalendar', { static: true }) calendar: FullCalendarComponent;

	@Input() persoId;
	@Input() defaultDate = moment().format('YYYY-MM-DD');

	private auftragSearch$ = new BehaviorSubject<string>(null);
    private subscription: Subscription;

	calendarOptionsViews = {
		timeGridWeek: { buttonText: this.translate.instant('txScheduler.week') },
		timeGridDay: { buttonText: this.translate.instant('txScheduler.day') },
		dayGridMonth: { buttonText: this.translate.instant('txScheduler.month'),
            dayCellContent: (arg) => {
                return this.updateCalendarHeaders(arg, 'grid')
            }
        },
		timeGridThreeDays: {
			type: 'timeGrid',
			duration: { days: 3 },
			buttonText: this.translate.instant('txScheduler.3days'),
			dateIncrement: { days: 1 },
		},
		timeGridWorkday: {
			type: 'timeGrid',
			hiddenDays: [0, 6],
			duration: { weeks: 1 },
			buttonText: this.translate.instant('txScheduler.5days'),
		},
	};

	newEventDialog = false;
	editEventDialog = false;
	editEventDialogTitle = this.translate.instant('general.show');
	myEditForm: FormGroup;
	myCreateForm: FormGroup;
	editEventType = '';
	dialogType = 'PZE';
	myCalendarKSTArray: Array<TxBdeKST> = [];
	session: TxSession;
	myEvents: Array<EventInput> = [];
	myCurrentPzeEvent: TxBuchung = new TxBuchung();
	myAuftragsArray: Array<TxBdeAuftrag> = null;
	fullAuftragsArray: Array<TxBdeAuftrag> = null;
	myAuftragsschrittArray: Array<TxBdeAuftragsschritt> = null;
	myBdeProtokoll: TxBDEProtokoll = null;
	blockedCalendar = false;
	myListOfWorkflowTyp = [];
	schedulerStartDate = moment().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss');
	schedulerEndDate = moment().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss');
	teamLeaderFor: number;
	myBuchungsTyp = []; // Buchungstypen je nach Einstellung am Benutzer
	TypeNeuItems: Array<any> = [];
	myCreateAntragModell: TxCreateWorkflowRequest = new TxCreateWorkflowRequest();
	showFcSettingsMenu = false;
	hourMode: any = defaultHourMode;
	translations: any;
	bookProtocol = [];
	calendarOptions: CalendarOptions = {
        dayHeaderContent: (arg) => {
            return this.updateCalendarHeaders(arg, 'col')
        },
		themeSystem: 'bootstrap',
		datesSet: this.datesRender.bind(this),
		eventDidMount: this.eventRender.bind(this),
		events: (info, success) => {
			success(this.myEvents);
		},
		firstDay: 1,
		eventClick: this.eventClick.bind(this),
		// dateClick: this.dragEvent.bind(this),
		select: this.dragEvent.bind(this),
		viewDidMount: this.viewSkeletonRender.bind(this),
		initialView: this.getDefaultView.bind(this)(),
		slotLabelFormat: {
			hour: '2-digit',
			minute: '2-digit',
		},
		headerToolbar: {
			left: 'myBackButton,next today',
			center: 'title',
			right: 'mySettingsButton',
		},
		views: this.calendarOptionsViews,
		expandRows: true,
		buttonText: {
			today: this.translate.instant('txScheduler.today') as string,
		},
		customButtons: {
			myBackButton: {
				text: this.translate.instant('txScheduler.back') as string,
				bootstrapFontAwesome: 'fa-chevron-left',
				click: (info) => {
					const viewName = this.calendar.getApi().view.type;
					if (viewName.includes('timeGridThreeDays')) {
						this.calendar.getApi().incrementDate({ days: -1 });
					} else {
						this.calendar.getApi().prev();
					}
				},
			},
			mySettingsButton: {
				text: this.translate.instant('txScheduler.settings'),
				bootstrapFontAwesome: 'fa-cog',
				click: (info) => {
					this.toggleSettingsMenu();
					const button = document.querySelectorAll('.fc-mySettingsButton-button')[0] as HTMLElement;
					const { height, top } = button.getBoundingClientRect();
					const settings = document.querySelectorAll('.fc-settings')[0] as HTMLElement;
					settings.style.top = height + 20 + 'px';
				},
			},
		},
		locales: [{ code: this.translate.currentLang.slice(0, 2) }],
		allDaySlot: true,
		allDayText: '',
		//defaultAllDay: true,
		navLinks: true,
		selectable: true,
		selectMirror: true,
		eventLongPressDelay: 1000,
		editable: false,
		scrollTime: '06:00:00',
		defaultTimedEventDuration: '00:15:00',
		height: '100%',
		//contentHeight: 650,
		slotEventOverlap: false,
		eventTimeFormat: 'H:mm',
		displayEventEnd: true,
		slotDuration: '00:30:00',
	};

    private cutoffDate: string | null = null;
    private cutoffDateLoaded$ = new BehaviorSubject<boolean>(false);
    public timemodels$: Observable<{ label: string, value: number }[]>;
    public isTimemodelSelectActive = false;

	constructor(
        private calendarService: CalendarService,
		private myTxApiService: TxApiService,
		public myAuthService: AuthService,
		private fb: FormBuilder,
		private translate: TranslateService,
		private msg: MessengerService,
		private planService: PlansService,
		private appConfigService: AppConfigService,
		private config: PrimeNGConfig,
        private store: Store,
        private dialog: MatDialog,
        private readonly localStorageService: LocalStorageService
	) {
        this.timemodels$ = this.store.select(getTimemodels).pipe(
            map((timemodels) => timemodels.map(t => ({
                value: t.model_id,
                label: t.model_name
            })))
        );

		this.config.setTranslation({
			dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			dayNames: ['Sontag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
			monthNames: [
				'Januar',
				'Februar',
				'März',
				'April',
				'Mai',
				'Juni',
				'Juli',
				'August',
				'Semtember',
				'Oktober',
				'November',
				'Dezember',
			],
			monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		});
		this.session = myAuthService.getSession();
		this.translate.onLangChange.subscribe((lang) => {
			this.calendar.getApi().setOption('locale', lang.lang);
			this.translations = lang.translations;
		});
		this.translate
			.getTranslation(this.translate.currentLang)
			.subscribe((translation) => (this.translations = translation));

		this.auftragSearch$
			.asObservable()
			.pipe(debounceTime(300))
			.subscribe((value) => {
				if (!value) {
					this.myAuftragsArray = this.fullAuftragsArray ?? [];
				} else {
					this.myAuftragsArray = this.fullAuftragsArray
						.filter((auftrag) => {
							return auftrag.label.toLowerCase().includes(value.toLowerCase());
						})
						.slice(0, 100);
				}
			});
	}

	/*timeSelected(picker: NgxMaterialTimepickerComponent, event: string) {
	    picker.timeSet.emit(event);
	    picker.close();
    }*/

	get filteredEvents() {
		return this.getUnique(this.myEvents, 'id');
	}

	getUnique(arr, comp) {
		// store the comparison values in array
		const unique = arr
			.map((e) => e[comp])
			// store the keys of the unique objects
			.map((e, i, final) => final.indexOf(e) === i && i)
			// eliminate the dead keys & return unique objects
			.filter((e) => arr[e])
			.map((e) => arr[e]);
		return unique;
	}

	eventRender(info) {
		const { event, el, view } = info;
		if (event.extendedProps.isPlan) {
			el.classList.add('overflow-hidden');
			if (el.querySelectorAll('.fc-event-title').length === 1) {
				const plan = event.extendedProps.plan;
				const timeText = document.createElement('div');
				timeText.className = 'fc-event-title';
				timeText.innerHTML = `${moment(plan.begin_time).format('HH:mm')} - ${moment(plan.end_time).format('HH:mm')}`;
				el.appendChild(timeText);
			}
		} else {
			if (el.querySelector('.fc-event-title') && el.querySelector('.fc-event-title').innerHTML !== event.title) {
				el.querySelector('.fc-event-title').innerHTML = event.title;
			}
			if (event.extendedProps.info && view.type !== 'dayGridMonth') {
				const descriptionContainer = document.createElement('div');
				descriptionContainer.className = 'fc-description';
				descriptionContainer.innerHTML = event.extendedProps.info;
				el.querySelector('.fc-event-title-container')?.appendChild(descriptionContainer);
			}
		}
	}

	datesRender(info) {
		const { view } = info;
		localStorage.setItem('txSchedulerDefaultView41', view.type);
		if (this.calendar) {
			localStorage.setItem('txSchedulerDefaultDate', this.calendar.getApi().getDate().toISOString());
		}
		this.loadEvents();
	}

	viewSkeletonRender(info) {}

	changeView(view: string) {
		localStorage.setItem('txSchedulerDefaultView41', view);
		this.toggleSettingsMenu();
		try {
			const calendarApi = this.calendar.getApi();
			calendarApi.changeView(view);
		} catch (e) {
			console.log(e);
		}
	}

	toggleHourMode() {
		this.toggleSettingsMenu();
		const calendarApi = this.calendar.getApi();
		if (this.hourMode === defaultHourMode) {
			this.hourMode = 'auto';
			//calendarApi.setOption('height', this.hourMode);
		} else {
			this.hourMode = defaultHourMode;
			//calendarApi.setOption('height', this.hourMode);
			calendarApi.scrollToTime('06:00');
		}
		localStorage.setItem('txSchedulerDefaultIntervalMode', `${this.hourMode}`);
	}

	toggleSlotMode() {
		this.toggleSettingsMenu();
		const calendarApi = this.calendar.getApi();
		const curSlotDuration = calendarApi.getOption('slotDuration');
		if (curSlotDuration === '00:30:00') {
			calendarApi.setOption('slotDuration', '00:60:00');
		} else {
			calendarApi.setOption('slotDuration', '00:30:00');
		}
		localStorage.setItem('txSchedulerDefaultSlotDuration', calendarApi.getOption('slotDuration').toString());
        this.calendarService.triggerRefreshCalendar();
	}

	toggleSettingsMenu() {
		this.showFcSettingsMenu = !this.showFcSettingsMenu;
	}

	prepareDefaultDate() {
		// Check if default date is chosen by route
		if (this.defaultDate && moment(this.defaultDate).isValid()) {
			return moment(this.defaultDate).format();
		}

		// Check if default date is chosen by localstorage
		const defaultDate = localStorage.getItem('txSchedulerDefaultDate');
		if (defaultDate && moment(defaultDate).isValid()) {
			return moment(defaultDate).format();
		}

		// Return now
		return moment().format();
	}

	checkPriviliges() {
		if ((this.editEventType === 'PZE' || this.editEventType === 'FZ') && this.session.calendar_pze !== 0) {
			this.myEditForm.enable({ onlySelf: true, emitEvent: false });
		} else if (this.editEventType === 'BDE' && this.session.calendar_bde === 1) {
			this.myEditForm.enable({ onlySelf: true, emitEvent: false });
		} else if (this.teamLeaderFor) {
			this.myEditForm.enable({ onlySelf: true, emitEvent: false });
		} else {
			this.myEditForm.disable({ onlySelf: true, emitEvent: false });
		}
	}

	onSearchAuftrag(event: KeyboardEvent) {
		if (event.target instanceof HTMLInputElement) {
			this.auftragSearch$.next(event.target.value);
		}
	}

	eventClick(eventClickInfo) {
		const { event, el, jsEvent, view } = eventClickInfo;
		const calEvent = event;
		// Do not open the dialog on non clickable events
		if (!calEvent.extendedProps.clickable && calEvent.extendedProps.type === 'FZ') {
			return;
		}

        switch (calEvent.extendedProps.type) {
            case 'FZ':
                if((this.session.team_leader === 1 && this.teamLeaderFor) || (this.session.calendar_pze > 0)) {
                    this.openDialog(TxSchedulerFzDialogComponent, {
                        id: calEvent.id,
                        type: calEvent.extendedProps.fehlzeit_id,
                        start: moment(calEvent.extendedProps.datum ?? calEvent.start).format('YYYY-MM-DD'),
                        end: moment(calEvent.extendedProps.datum ?? calEvent.start).format('YYYY-MM-DD'),
                        note: calEvent.extendedProps.info,
                        teamLeaderFor: !!this.teamLeaderFor
                    } as TxSchedulerFzDialogData);
                }
                break;
            default:
                if (
                    (calEvent.extendedProps.type.toLowerCase() === 'pze' && this.session.calendar_pze > 0)
                ) {
                    this.openDialog(TxSelectTypeDialogComponent, {
                        type: calEvent.extendedProps.type.toLowerCase() as 'select' | 'bde' | 'pze',
                        id: +(calEvent.extendedProps.bde_id ?? (calEvent.extendedProps.beginn_id ?? calEvent.extendedProps.ende_id)),
                        mustCreateWorkflow: [this.session.calendar_pze === 2, this.session.calendar_bde === 2],
                        start: moment(calEvent.extendedProps.beginn as string, 'YYYY-MM-DDTHH:mm:ss'),
                        end: moment(calEvent.extendedProps.ende as string, 'YYYY-MM-DDTHH:mm:ss'),
                        timemodel_id: calEvent.extendedProps.timemodel_id,
                        note: calEvent.extendedProps.notiz ?? '',
                        kst_number: +calEvent.extendedProps.kst_nummer,
                        auftrag_nummer: calEvent.extendedProps.auftrag_nummer,
                        schritt_nummer: calEvent.extendedProps.schritt_nummer,
                        quantity: +calEvent.extendedProps.quantity,
                        teamLeaderFor: !!this.teamLeaderFor
                    });
                } else if(
                    (this.session.team_leader === 1 && this.teamLeaderFor) ||
                    (calEvent.extendedProps.type.toLowerCase() === 'bde' && this.session.calendar_bde > 0)){
                    this.openDialog(TxSelectTypeDialogComponent, {
                        type: calEvent.extendedProps.type.toLowerCase() as 'select' | 'bde' | 'pze',
                        id: +(calEvent.extendedProps.bde_id ?? (calEvent.extendedProps.beginn_id ?? calEvent.extendedProps.ende_id)),
                        mustCreateWorkflow: [this.session.calendar_pze === 2, this.session.calendar_bde === 2],
                        start: moment(calEvent.extendedProps.beginn as string, 'YYYY-MM-DDTHH:mm:ss'),
                        end: moment(calEvent.extendedProps.ende as string, 'YYYY-MM-DDTHH:mm:ss'),
                        timemodel_id: calEvent.extendedProps.timemodel_id,
                        note: calEvent.extendedProps.notiz ?? calEvent.extendedProps.info,
                        kst_number: +calEvent.extendedProps.kst_nummer,
                        auftrag_nummer: calEvent.extendedProps.auftrag_nummer,
                        schritt_nummer: calEvent.extendedProps.schritt_nummer,
                        quantity: +calEvent.extendedProps.quantity,
                        teamLeaderFor: !!this.teamLeaderFor
                    });
                }
                break;
        }

        /*this.isTimemodelSelectActive = !!calEvent.extendedProps.timemodel_id;

		this.getBookProtocol(moment(calEvent.extendedProps.datum ?? calEvent.start).format('YYYY-MM-DD') + 'T00:00:00');
		switch (calEvent.extendedProps.type) {
			case 'PZE':
				this.editEventDialog = true;
				this.editEventDialogTitle = this.translations.txScheduler.pze;
				this.editEventType = 'PZE';
				break;
			case 'BDE':
				this.editEventDialog = true;
				this.editEventDialogTitle = this.translations.txScheduler.bde;
				this.editEventType = 'BDE';
				break;
			case 'FZ':
				this.editEventDialog = true;
				this.editEventDialogTitle = this.translations.txScheduler.missingTime;
				this.editEventType = 'FZ';
				break;
		}*/

		//this.checkPriviliges();
		//this.blockedCalendar = true;
		//this.resizeWindowFix();
	}

    private async openDialog(component: ComponentType<
        TxSelectTypeDialogComponent |
        TxSchedulerFzDialogComponent>, data: TxSelectTypeDialogData | TxSchedulerFzDialogData = null
    ) {

        this.bookProtocol = await this.getBookProtocol(moment(data.start).format('YYYY-MM-DD') + 'T00:00:00');

        const dialogData = {
            ...data,  // Include existing data if any
            bookProtocol: this.bookProtocol  // Pass the bookProtocol data
        };
        const dialogRef = this.dialog.open(component, {
            /*width: '840px',
            minWidth: '320px',
            disableClose: true,*/
            autoFocus: true,
            data: dialogData
        });

        const result: {
            type: 'bde' | 'pze' | 'fz',
            data: TxSchedulerFzDialogResult | TxSchedulerPzeResult | TxSchedulerBdeResult
        } = await lastValueFrom(dialogRef.afterClosed());

        console.log('Dialog geschlossen, Ergebnis:', result, data);
        this.loadEvents();

        if (result) {
            return data.id ? this.updateNewEvent(result, data) : this.createNewEvent(result);
        } else {
            this.blockedCalendar = false;
            this.resetForm();
        }

        return null;
    };

    updateCalendarHeaders(arg: any, viewType: 'grid' | 'col' = 'grid'): string {
        const schedulerDateRange = {
            von: moment(arg.date).format('YYYY-MM-DDTHH:mm:ss'),
            bis: moment(arg.date).format('YYYY-MM-DDTHH:mm:ss'),
            team_leader_for: this.teamLeaderFor,
        };

        // Direkt den Standard-Text zurückgeben
        const defaultContent = arg.text;

        // Konfiguration für verschiedene View-Typen
        const viewConfig = {
            grid: {
                headerSelector: '.fc-daygrid-day',
                contentSelector: '.fc-daygrid-day-number'
            },
            col: {
                headerSelector: '.fc-col-header-cell',
                contentSelector: '.fc-col-header-cell-cushion'
            }
        };

        // API-Aufruf asynchron durchführen
        this.myTxApiService.callAPI('getCalendarHead', schedulerDateRange).pipe(
            map((res) => {
                return res.ListOfCalendarHead
                    .filter((schedulerData: ListOfCalendarHead) => schedulerData.special_day !== null)
                    .map((schedulerData: ListOfCalendarHead) => {
                        return {
                            date: moment(schedulerData.datum).format('YYYY-MM-DD'),
                            special_day: schedulerData.special_day,
                            title: (schedulerData.fehlzeit_name ?? '') + (schedulerData.info ?? '')
                        };
                    });
            })
        ).subscribe({
            next: (entries) => {
                // Nach der API-Antwort die Header manuell aktualisieren
                const { headerSelector, contentSelector } = viewConfig[viewType];
                const headerElements = document.querySelectorAll(headerSelector);

                headerElements.forEach((headerEl: HTMLElement) => {
                    const headerDate = moment(headerEl.getAttribute('data-date')).format('YYYY-MM-DD');
                    const matchingEntry = entries.find(entry => entry.date === headerDate);

                    if (matchingEntry) {
                        const headerContent = headerEl.querySelector(contentSelector);
                        if (headerContent) {
                            const originalText = headerContent.textContent?.trim() || '';
                            headerContent.innerHTML = `<span class="txc-cal-specialdaycolor">${originalText} (${matchingEntry.special_day})</span>`;
                        }
                    }
                });
            },
            error: (error) => {
                console.error('Fehler beim Laden der Kalenderdaten:', error);
            }
        });

        return defaultContent;
    }

	dragEvent(info: DateSelectArg) {
		let { start, end } = info;
		const startM = moment(start);
		let endM = moment(end);
		if (endM.format('HH:mm:ss') === '00:00:00') {
			endM = endM.subtract(1, 'm');
		}
		const momentDragStart = startM.clone().startOf('day');
		const momentDragEnd = endM.clone().startOf('day');

		if (info.allDay || !momentDragStart.isSame(momentDragEnd)) {
			const blockingFullDayEvents = this.myEvents.filter((event) => {
				const eventStart = moment(event.start);
				return (
					// check if event is in selected date range
					(eventStart.isBetween(momentDragStart, momentDragEnd) ||
						eventStart.isSame(momentDragStart.startOf('day')) ||
						eventStart.isSame(momentDragEnd.startOf('day'))) &&
					(event.extendedProps as SchedulerEvent)?.fehlzeit_id !== null && // dont react to info messages
					event.allDay // only allday events are relevant for blocking
				);
			});

			if (blockingFullDayEvents.length > 0) {
				this.blockedCalendar = true;
                const dialog = this.dialog.open(TxWarningDialogComponent, {
                    width: '640px',
                    minWidth: '320px',
                    autoFocus: false,
                    disableClose: false,
                });

				this.resizeWindowFix();
				return;
			}
			this.dialogType = 'FZ';
		} else {
            this.dialogType = '';
        }

        this.isTimemodelSelectActive = false;

		this.appConfigService.personalAccountsConfig$.pipe(take(1)).subscribe(async (config) => {
			let bookingType = config.CalendarEntityConfig?.bookingType ?? this.dialogType;
            console.log('session',this.session)
			if (bookingType === 'FZ' || this.dialogType === 'FZ') {
				bookingType = 'FZ';
			} else if (
                (this.session.team_leader && this.teamLeaderFor) ||
                (this.session.calendar_pze + this.session.calendar_bde) > 1) {
                bookingType = 'select'
            } else if (this.session.calendar_bde > 0) {
                bookingType = 'BDE';
            } else if (this.session.calendar_pze > 0) {
                bookingType = 'PZE'
            }

            switch (bookingType) {
                case 'FZ':
                    if((this.session.team_leader === 1 && this.teamLeaderFor) || (this.session.calendar_pze > 0)){
                        await this.openDialog(TxSchedulerFzDialogComponent, {
                            start: startM.format('YYYY-MM-DD'),
                            end: endM.format('YYYY-MM-DD'),
                            teamLeaderFor: !!this.teamLeaderFor
                        } as TxSchedulerFzDialogData);
                    }
                    break;
                default:
                    let auftrag_nummer: string | null = this.localStorageService.get<string | null>('bdeAuftragNummer');
                    const type = bookingType.toLowerCase() as 'select' | 'bde' | 'pze';
                    await this.openDialog(TxSelectTypeDialogComponent, {
                        type,
                        start: startM,
                        end: endM,
                        mustCreateWorkflow: [this.session.calendar_pze === 2, this.session.calendar_bde === 2],
                        teamLeaderFor: !!this.teamLeaderFor,
                        auftrag_nummer,
                        /*kst_nummer: config.CalendarEntityConfig?.costCentre ?? null,
                        auftrag_nummer: config.CalendarEntityConfig?.assignment ?? null,
                        datum: start.format('DD.MM.YYYY'),
                        book_beginn: start.format('HH:mm'),
                        book_end: end.format('HH:mm'),
                        fzstart: start.format('DD.MM.YYYY'),
                        fzende: end.subtract(1, 'minutes').format('DD.MM.YYYY'),
                        quantity: 0,
                        info: '',
                        request_datum: start.format('DD.MM.YYYY'),
                        vonBuchungDatumField: start,
                        request_time: start.format('HH:mm'),*/
                    });
                    break;
            }


            // this.newEventDialog = true;
			// this.blockedCalendar = true;
			this.resizeWindowFix();
		});
	}

	resizeWindowFix() {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1);
	}

	resetForm() {
		this.newEventDialog = false;
		this.blockedCalendar = false;
		this.myCreateForm.reset();
		this.auftragSearch$.next(null);
	}

	prepareCreate(requestType = 1) {
		const formModel = this.myCreateForm.value;
		let myBuchungsData: any;
		if (requestType === 1) {
			myBuchungsData = new TxCreateBooking();

			if (formModel.type === 'FZ') {
				myBuchungsData.book_beginn = moment(formModel.fzstart, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T00:00:00';
				myBuchungsData.book_end = moment(formModel.fzende, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T23:59:59';
			} else {
				myBuchungsData.book_beginn =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.book_beginn + ':00';
				myBuchungsData.book_end =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.book_end + ':00';
			}
			myBuchungsData.book_type = formModel.type;
			myBuchungsData.kst_nummer = formModel.kst_nummer ? formModel.kst_nummer : 0;
			myBuchungsData.timemodel_id = formModel.timemodel_id ? formModel.timemodel_id : 0;
			myBuchungsData.auftrag_nummer = formModel.auftrag_nummer ? formModel.auftrag_nummer : '';
			myBuchungsData.schritt_nummer = formModel.schritt_nummer ? formModel.schritt_nummer : '';
			myBuchungsData.fehlzeit_id = formModel.fehlzeit_id ? parseInt(formModel.fehlzeit_id, 10) : 0;
			myBuchungsData.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
			myBuchungsData.info = formModel.info ? formModel.info : '';
		} else {
			myBuchungsData = new TxCreateWorkflowRequest();
			if (formModel.type === 'FZ') {
				myBuchungsData.Art = 'FZB';
				myBuchungsData.von = moment(formModel.fzstart, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T00:00:00';
				myBuchungsData.bis = moment(formModel.fzende, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T23:59:59';
				myBuchungsData.Typ = formModel.fehlzeit_id ? parseInt(formModel.fehlzeit_id, 10) : 0;
			} else {
				myBuchungsData.Art = 'KG';
				myBuchungsData.von =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.book_beginn + ':00';
				myBuchungsData.bis =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.book_end + ':00';
				myBuchungsData.kst_nummer = formModel.kst_nummer ? formModel.kst_nummer : 0;
                myBuchungsData.timemodel_id = formModel.timemodel_id ? formModel.timemodel_id : 0;
			}
			myBuchungsData.Notiz = formModel.info ? formModel.info : '';
		}

		return myBuchungsData;
	}

	prepareUpdate(requestType = 1) {
		const formModel = this.myEditForm.value;
		let myUpdateBuchungsData: any;

		if (requestType === 1) {
			myUpdateBuchungsData = new TxUpdateBooking();
			if (formModel.type === 'FZ') {
				myUpdateBuchungsData.book_beginn = moment(formModel.fzstart, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T00:00:00';
				myUpdateBuchungsData.book_end = moment(formModel.fzende, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T23:59:59';
				formModel.id = formModel.id.replace('FZ', '');
			} else {
				myUpdateBuchungsData.book_beginn =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.start + ':00';
				myUpdateBuchungsData.book_end =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.ende + ':00';
			}
			myUpdateBuchungsData.book_type = formModel.type;
			myUpdateBuchungsData.book_beginn_id = formModel.beginn_id ? parseInt(formModel.beginn_id, 10) : formModel.id;
			myUpdateBuchungsData.book_end_id = formModel.ende_id ? parseInt(formModel.ende_id, 10) : 0;
			myUpdateBuchungsData.fehlzeit_id = formModel.fehlzeit ? parseInt(formModel.fehlzeit, 10) : 0;
			myUpdateBuchungsData.kst_nummer = formModel.kst ? parseInt(formModel.kst, 10) : 0;
            myUpdateBuchungsData.timemodel_id = formModel.timemodel_id ? parseInt(formModel.timemodel_id, 10) : 0;
			myUpdateBuchungsData.auftrag_nummer = formModel.auftrag;
			myUpdateBuchungsData.schritt_nummer = formModel.schritt;
			myUpdateBuchungsData.info = formModel.info ? formModel.info : '';
			myUpdateBuchungsData.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
			myUpdateBuchungsData.quantity = formModel.quantity ?? 0;
		} else {
			myUpdateBuchungsData = new TxCreateWorkflowRequest();

			if (formModel.type === 'FZ') {
				myUpdateBuchungsData.Art = 'FZB';
				myUpdateBuchungsData.von = moment(formModel.fzstart, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T00:00:00';
				myUpdateBuchungsData.bis = moment(formModel.fzende, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T23:59:59';
				myUpdateBuchungsData.Typ = formModel.fehlzeit ? parseInt(formModel.fehlzeit, 10) : 0;
			} else {
				myUpdateBuchungsData.Art = 'KG';
				myUpdateBuchungsData.von =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.start + ':00';
				myUpdateBuchungsData.bis =
					moment(formModel.datum, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + formModel.ende + ':00';
				myUpdateBuchungsData.kst_nummer = formModel.kst_nummer ? formModel.kst_nummer : 0;
				myUpdateBuchungsData.timemodel_id = formModel.timemodel_id ? formModel.timemodel_id : 0;
			}
			myUpdateBuchungsData.Notiz = formModel.info ? formModel.info : '';
			let buchungsId = 0;
			if (formModel.beginn_id) {
				buchungsId = parseInt(formModel.beginn_id, 10);
			} else if (formModel.ende_id) {
				buchungsId = parseInt(formModel.ende_id, 10);
			} else {
				buchungsId = parseInt(formModel.id.replace('FZ', ''), 10);
			}
			myUpdateBuchungsData.BuchungsId = buchungsId;
			myUpdateBuchungsData.quantity = formModel.quantity ?? 0;
		}

		return myUpdateBuchungsData;
	}

	prepareDelete() {
		const formModel = this.myEditForm.value;
		const myDeleteBuchungsData = new TxDeleteBooking();

		if (formModel.type === 'FZ') {
			formModel.id = formModel.id.replace('FZ', '');
		}

		myDeleteBuchungsData.book_type = formModel.type;
		myDeleteBuchungsData.book_beginn_id = formModel.beginn_id ? formModel.beginn_id : formModel.id;
		myDeleteBuchungsData.book_end_id = formModel.ende_id ? formModel.ende_id : '0';

		return myDeleteBuchungsData;
	}

    async updateNewEvent(result: {
        type: 'bde' | 'pze' | 'fz',
        data: TxSchedulerFzDialogResult | TxSchedulerPzeResult | TxSchedulerBdeResult
    }, oldData) {
        const { data, type } = result;
        let res: ApiResponseType = {
            statuscode: -1,
            statustext: 'Es konnte keine Abfrage gesendet werden.',
            hash: '',
        };
        switch (type) {
            case 'fz':
                const fzData = data as TxSchedulerFzDialogResult;
                if ((this.session.team_leader === 1 && this.teamLeaderFor) || this.session.calendar_pze === 1) {
                    const payload = new TxUpdateBooking();
                    payload.book_beginn = `${ moment(fzData.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T00:00:00`;
                    payload.book_end = `${ moment(fzData.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T23:59:59`;
                    payload.book_beginn_id = +(fzData.id.replace('FZ', ''));
                    payload.book_end_id = 0;
                    payload.info = fzData.note;
                    payload.book_type = type;
                    payload.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                    payload.fehlzeit_id = fzData.bookingType ? parseInt(fzData.bookingType.nummer, 10) : 0;

                    res = await lastValueFrom(this.myTxApiService.callAPI('setBookPeriod', payload));
                } else if (this.session.calendar_pze === 2) {
                    const payload = new TxCreateWorkflowRequest();
                    payload.Art = 'FZB';
                    payload.von = `${ moment(fzData.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T00:00:00`;
                    payload.bis = `${ moment(fzData.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T23:59:59`;
                    payload.Typ = fzData.bookingType ? fzData.bookingType.nummer : '0';
                    payload.Notiz = fzData.note ?? '';
                    payload.BuchungsId = +fzData.id.replace('FZ', '');

                    res = await lastValueFrom(this.myTxApiService.callAPI('createWorkflow', payload));
                }

                break;
            case 'bde':
                const bdeData = data as TxSchedulerBdeResult;

                const payload = new TxUpdateBooking();
                payload.book_beginn =
                    moment(bdeData.date).format('YYYY-MM-DD') + 'T' + bdeData.start + ':00';
                payload.book_end =
                    moment(bdeData.date).format('YYYY-MM-DD') + 'T' + bdeData.end + ':00';
                payload.book_type = type;
                payload.book_beginn_id = oldData.id;
                payload.book_end_id = 0;
                payload.auftrag_nummer = bdeData.auftrag_nummer;
                payload.schritt_nummer = bdeData.schritt_nummer;
                payload.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                payload.info = bdeData.note;
                payload.quantity = +bdeData.quantity;

                this.localStorageService.set('bdeAuftragNummer', bdeData.auftrag_nummer);

                res = await lastValueFrom(this.myTxApiService.callAPI('updateBooking', payload));
                break;
            case 'pze':
                const pzeData = data as TxSchedulerPzeResult;
                if ((this.session.team_leader === 1 && this.teamLeaderFor) || this.session.calendar_pze === 1) {
                    const payload = new TxUpdateBooking();

                    payload.book_beginn =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.start + ':00';
                    payload.book_end =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.end + ':00';

                    payload.book_type = type;
                    payload.book_beginn_id = oldData.id;
                    payload.book_end_id = oldData.id;
                    payload.kst_nummer = pzeData.kst_number;
                    payload.timemodel_id = pzeData.timemodel_id;
                    payload.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                    payload.info = pzeData.note ? pzeData.note : '';

                    res = await lastValueFrom(
                        this.myTxApiService.callAPI('updateBooking', payload).pipe(
                            delay(1000)
                        )
                    );
                } else if (this.session.calendar_pze === 2) {
                    const payload = new TxCreateWorkflowRequest();

                    payload.Art = 'KG';
                    payload.von =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.start + ':00';
                    payload.bis =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.end + ':00';
                    payload.Notiz = pzeData.note;
                    payload.BuchungsId = pzeData.id;

                    res = await lastValueFrom(this.myTxApiService.callAPI('createWorkflow', payload));
                }
                break;
            default:
                break;
        }

        if (res.statuscode !== 0) {
            Messenger().post({
                message: res.statustext,
                type: 'error',
                showCloseButton: true,
            });
        } else {
            Messenger().post({
                message: res.statustext,
                type: 'success',
                showCloseButton: true,
                hideAfter: 5,
            });
        }

        await this.loadEvents(null, type);
    }

	updateEvent(dialogType: 'BDE' | 'PZE' | 'FZ') {
		let apiAnfrage: Observable<TxBuchung>;
		let myResponse: any;
		let endpoint = '';
		let myUpdateBuchungsData;

		if (dialogType === 'BDE') {
			myUpdateBuchungsData = this.prepareUpdate(1);
			endpoint = 'updateBooking';
		} else {
			if (!!this.teamLeaderFor || this.session.calendar_pze === 1) {
				myUpdateBuchungsData = this.prepareUpdate(1);
				endpoint = 'updateBooking';
			} else {
				myUpdateBuchungsData = this.prepareUpdate(2);
				endpoint = 'createWorkflow';
			}
		}

		apiAnfrage = this.myTxApiService.callAPI(endpoint, myUpdateBuchungsData);
		apiAnfrage.subscribe(
			(mySessionData) => {
				myResponse = mySessionData;
				this.msg.message(myResponse.statustext, 'success');
				/*Messenger().post({
					message: myResponse.statustext,
					type: 'success',
					showCloseButton: true,
					hideAfter: 5,
				});*/
			},
			(error) => {
				console.log('ERROR' + error);
				this.msg.message(`ERROR ${error}`, 'error');
				/*Messenger().post({
					message: 'ERROR' + error,
					type: 'error',
					showCloseButton: true,
				});*/
			},
			() => {
				this.editEventDialog = false;
				this.blockedCalendar = false;
				this.loadEvents(null, myUpdateBuchungsData.book_type);
			}
		);
	}

	deleteEvent() {
		const deleteCheck = confirm('Möchten Sie diesen Eintrag wirklich löschen?');
		if (!deleteCheck) {
			return;
		}
		const myDeleteBuchungsData = this.prepareDelete();
		const apiAnfrage: Observable<TxBuchung> = this.myTxApiService.callAPI('deleteBooking', myDeleteBuchungsData);

		apiAnfrage.subscribe(
			(mySessionData: any) => {
				Messenger().post({
					message: mySessionData.statustext,
					type: 'success',
					showCloseButton: true,
					hideAfter: 5,
				});
			},
			(error) => {
				Messenger().post({
					message: 'ERROR' + error,
					type: 'error',
					showCloseButton: true,
				});
			},
			() => {
				this.loadEvents(null, myDeleteBuchungsData.book_type);
				this.editEventDialog = false;
				this.blockedCalendar = false;
			}
		);
	}

    getBookProtocol(date): Promise<any> {
        return new Promise((resolve, reject) => {
            let apiAnfrage: Observable<any>;

            apiAnfrage = this.myTxApiService.callAPI('getBookProtocol', {
                bookDate: date,
                team_leader_for: this.teamLeaderFor,
            });

            apiAnfrage.subscribe({
                next: (myBookProtocol) => {
                    this.bookProtocol = [];
                    this.bookProtocol = myBookProtocol.ListOfBookProtocol.map((data) => {
                        const myBookDate = moment(data.BookDate);
                        return {
                            Personalnummer: data.Personalnummer,
                            BookDate: myBookDate.format('DD.MM.YYYY'),
                            BookTime: myBookDate.format('H:mm'),
                            BookType: data.BookType,
                            BookError: data.BookError,
                        };
                    });
                    resolve(this.bookProtocol);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    async createNewEvent(result: {
        type: 'bde' | 'pze' | 'fz',
        data: TxSchedulerFzDialogResult | TxSchedulerPzeResult | TxSchedulerBdeResult
    })
    {
        const { data, type } = result;
        // console.log(type, data);
        let res: ApiResponseType = {
            statuscode: -1,
            statustext: 'Es konnte keine Abfrage gesendet werden.',
            hash: '',
        };
        switch (type) {
            case 'fz':
                const fzData = data as TxSchedulerFzDialogResult;
                if ((this.session.team_leader === 1 && this.teamLeaderFor) || this.session.calendar_pze === 1) {
                    const payload = new TxCreateBooking();

                    payload.book_beginn = `${ moment(fzData.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T00:00:00`;
                    payload.book_end = `${ moment(fzData.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T23:59:59`;
                    payload.book_type = type;
                    payload.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                    payload.fehlzeit_id = fzData.bookingType ? parseInt(fzData.bookingType.nummer, 10) : 0;

                    res = await lastValueFrom(this.myTxApiService.callAPI('setBookPeriod', payload));
                } else if (this.session.calendar_pze === 2) {
                    const payload = new TxCreateWorkflowRequest();
                    payload.Art = 'FZB';
                    payload.von = `${ moment(fzData.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T00:00:00`;
                    payload.bis = `${ moment(fzData.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD') }T23:59:59`;
                    payload.Typ = fzData.bookingType ? fzData.bookingType.nummer : '0';
                    payload.Notiz = fzData.note ?? '';

                    res = await lastValueFrom(this.myTxApiService.callAPI('createWorkflow', payload));
                }
                break;
            case 'bde':
                const bdeData = data as TxSchedulerBdeResult;
                const payload = new TxCreateBooking();
                payload.book_beginn =
                    moment(bdeData.date).format('YYYY-MM-DD') + 'T' + bdeData.start + ':00';
                payload.book_end =
                    moment(bdeData.date).format('YYYY-MM-DD') + 'T' + bdeData.end + ':00';
                payload.book_type = type;
                payload.auftrag_nummer = bdeData.auftrag_nummer;
                payload.schritt_nummer = bdeData.schritt_nummer;
                payload.team_leader_for = this.teamLeaderFor ? this.teamLeaderFor : 0;
                payload.info = bdeData.note;
                payload.quantity = +bdeData.quantity;

                res = await lastValueFrom(this.myTxApiService.callAPI('setBookPeriod', payload));
                break;
            case 'pze':
                const pzeData = data as TxSchedulerPzeResult;
                if ((this.session.team_leader === 1 && this.teamLeaderFor) || this.session.calendar_pze === 1) {
                    const payload = new TxCreateBooking();

                    payload.book_beginn =
                        moment(pzeData.date).format('YYYY-MM-DD')+ 'T' + pzeData.start + ':00';
                    payload.book_end =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.end + ':00';

                    payload.book_type = type;
                    payload.kst_nummer = pzeData.kst_number ?? 0;
                    payload.timemodel_id = pzeData.timemodel_id ?? 0;
                    payload.team_leader_for = this.teamLeaderFor ?? 0;
                    payload.info = pzeData.note ? pzeData.note : '';

                    res = await lastValueFrom(this.myTxApiService.callAPI('setBookPeriod', payload));
                } else if (this.session.calendar_pze === 2) {
                    const payload = new TxCreateWorkflowRequest();
                    payload.Art = 'KG';
                    payload.von =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.start + ':00';
                    payload.bis =
                        moment(pzeData.date).format('YYYY-MM-DD') + 'T' + pzeData.end + ':00';
                    payload.kst_nummer = pzeData.kst_number ?? 0;
                    payload.timemodel_id = pzeData.timemodel_id ?? 0;
                    payload.Notiz = pzeData.note;

                    res = await lastValueFrom(this.myTxApiService.callAPI('createWorkflow', payload));
                }
                console.log(data);
                break;
            default:
                break;
        }

        if (res.statuscode !== 0) {
            Messenger().post({
                message: res.statustext,
                type: 'error',
                showCloseButton: true,
            });
        } else {
            Messenger().post({
                message: res.statustext,
                type: 'success',
                showCloseButton: true,
                hideAfter: 5,
            });
        }

        await this.loadEvents(null, type);
    }

	createEvent(dialogType) {
		let apiAnfrage: Observable<any>;
		let myResponse: any;
		let endpoint = '';
		let myBuchungsData: any;

		if (dialogType === 'BDE') {
			myBuchungsData = this.prepareCreate(1);
			endpoint = 'setBookPeriod';
		} else {
			if ((this.session.team_leader === 1 && this.teamLeaderFor) || this.session.calendar_pze === 1) {
				myBuchungsData = this.prepareCreate(1);
				endpoint = 'setBookPeriod';
			} else if (this.session.calendar_pze === 2) {
				myBuchungsData = this.prepareCreate(2);
				endpoint = 'createWorkflow';
			} else {
				return;
			}
		}

		apiAnfrage = this.myTxApiService.callAPI(endpoint, myBuchungsData);

		apiAnfrage.subscribe(
			(mySessionData) => {
				myResponse = mySessionData;

				Messenger().post({
					message: myResponse.statustext,
					type: 'success',
					showCloseButton: true,
					hideAfter: 5,
				});

				this.newEventDialog = false;
				this.blockedCalendar = true;
			},
			(error) => {
				console.log('ERROR' + error);

				Messenger().post({
					message: 'ERROR' + error,
					type: 'error',
					showCloseButton: true,
				});
			},
			() => {
				if (this.session.calendar_pze > 0 || this.teamLeaderFor || dialogType === 'BDE') {
					this.loadEvents(null, dialogType);
				}
				this.editEventDialog = false;
				this.blockedCalendar = false;
				this.resetForm();
			}
		);
	}

	getSchedulerHeadEvents() {
		const schedulerDateRange = {
			von: this.schedulerStartDate,
			bis: this.schedulerEndDate,
			team_leader_for: this.teamLeaderFor,
		};
		return this.myTxApiService.callAPI('getCalendarHead', schedulerDateRange).pipe(
			map((res) => {
				return res.ListOfCalendarHead
                    .filter((schedulerData: ListOfCalendarHead) => schedulerData.special_day === null)
                    .map((schedulerData: ListOfCalendarHead) => {
					const datum = moment(schedulerData.datum).format('YYYY-MM-DD');
					const title = (schedulerData.fehlzeit_name ?? '') + (schedulerData.info ?? '');
					const currentEvent: EventInput = {
						// Global event settings
						id: schedulerData.id + 'FZ',
						title: title?.replace('null', '') ?? '',
						start: datum,
						end: datum,
						color: schedulerData.fehlzeit_color ? schedulerData.fehlzeit_color : '#dcdcdc',
						allDay: true,
                        special_day: schedulerData.special_day,
						// editable: !!schedulerData.fehlzeit_name,
						extendedProps: {
							type: 'FZ',
							fehlzeit_id: schedulerData.fehlzeit_id,
							fehlzeit_dauer: schedulerData.fehlzeit_dauer,
							fehlzeit_name: schedulerData.fehlzeit_name,
							clickable: !!schedulerData.fehlzeit_name,
						} as ListOfCalendarHead,
					};
					return currentEvent;
				}).filter(Boolean);
			})
		);
	}

    getSchedulerPZEEvents() {
        const schedulerDateRange = {
            von: this.schedulerStartDate,
            bis: this.schedulerEndDate,
            team_leader_for: this.teamLeaderFor,
        };

        return this.myTxApiService.callAPI('getCalendarPZE', schedulerDateRange).pipe(
            switchMap((res) =>
                this.timemodels$.pipe(
                    map((timemodels) => {
                        const events = [];
                        events.push(
                            ...res.ListOfCalendarPZE.map((schedulerData: ListOfCalendarPZE) => {
                                const timemodel = timemodels.find(t => t.value === schedulerData.timemodel_id);
                                const timemodelName = timemodel ? timemodel.label : '';

                                const currentEvent: EventInput = {
                                    id: schedulerData.id?.toString() ?? '',
                                    title: 'PZE' + (timemodelName ? '<br>' + timemodelName : ''),
                                    start: moment(schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende).toDate(),
                                    end: moment(schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn).toDate(),
                                    color: schedulerData.color,
                                    className: ['pze-event'],
                                    allDay: false,
                                    overlap: false,
                                    extendedProps: {
                                        datum: schedulerData.datum,
                                        type: 'PZE',
                                        info: schedulerData.info,
                                        notiz: schedulerData.notiz,
                                        beginn_id: schedulerData.beginn_id,
                                        beginn: schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende,
                                        ende_id: schedulerData.ende_id,
                                        ende: schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn,
                                        kst_nummer: schedulerData.kst_nummer,
                                        timemodel_id: schedulerData.timemodel_id,
                                        timemodel_name: timemodelName // Hier fügen wir den Timemodel-Namen zu den extendedProps hinzu
                                    } as ListOfCalendarPZE,
                                };
                                return currentEvent;
                            })
                        );
                        return events;
                    })
                )
            )
        );
    }


	getSchedulerBDEEvents() {
		const schedulerDateRange = {
			von: this.schedulerStartDate,
			bis: this.schedulerEndDate,
			team_leader_for: this.teamLeaderFor,
		};
		return this.myTxApiService.callAPI('getCalendarBDE', schedulerDateRange).pipe(
			map((res) => {
				return res.ListOfCalendarBDE.map((schedulerData: ListOfCalendarBDE) => {
					const currentEvent: EventInput = {
						// Global event settings
						id: schedulerData.id + '',
						title: schedulerData.schritt_name
							? schedulerData.auftrag_name + ' – ' + schedulerData.schritt_name + ' <br>' + this.translate.instant('general.quantity') + ': ' + schedulerData.quantity
							: schedulerData.auftrag_name + ' <br>' + this.translate.instant('general.quantity') + ': ' + schedulerData.quantity,
						start: schedulerData.beginn,
						end: schedulerData.ende,
						overlap: false,
						color: schedulerData.color,
						allDay: false,
						extendedProps: {
							datum: schedulerData.datum,
							type: 'BDE',
							beginn: schedulerData.beginn,
							ende: schedulerData.ende,
							auftrag_nummer: schedulerData.auftrag_nummer,
							schritt_nummer: schedulerData.schritt_nummer,
							bde_id: schedulerData.id,
							info: schedulerData.info,
							quantity: schedulerData.quantity,
						},
					};
					return currentEvent;
				});
			})
		);
	}

	async loadEvents(event?: any, type?: string) {
		const calendarApi = this.calendar.getApi();
		this.schedulerStartDate = moment(calendarApi.view.currentStart).format('YYYY-MM-DD') + 'T00:00:00';
		this.schedulerEndDate = moment(calendarApi.view.currentEnd).format('YYYY-MM-DD') + 'T00:00:00';

		const data: Observable<any>[] = [];
		if (type) {
			this.myEvents = this.myEvents.filter((myEvent) => myEvent.extendedProps.type !== type);
			switch (type.toLowerCase()) {
				case 'pze':
					data.push(this.getSchedulerPZEEvents());
					break;
				case 'bde':
					data.push(this.getSchedulerBDEEvents());
					break;
				case 'fz':
					data.push(this.getSchedulerHeadEvents());
					break;
			}
		} else {
			this.myEvents = [];
			data.push(this.getSchedulerHeadEvents(), this.getSchedulerPZEEvents(), this.getSchedulerBDEEvents());
		}

		data.push(this.getPlanEvents().pipe(take(1)));

        // console.log(type, data);

		combineLatest(data)
			.pipe(take(1))
			.subscribe((e) => {
				const newIds = e.flat().map(e => e.id);

                this.myEvents = this.myEvents.filter((e) => !newIds.includes(e.id));
                this.myEvents.push(...e.flat());
                this.myEvents = this.myEvents.filter(Boolean);
				this.calendar.getApi().getEventSourceById('').refetch();
			});
	}

	getPlanEvents() {
		return this.planService
			.fetchCalendarList(
				moment(this.calendar.getApi().view.currentStart).format('YYYY-MM-DD'),
				moment(this.calendar.getApi().view.currentEnd).subtract(1, 'days').format('YYYY-MM-DD'),
				!!this.teamLeaderFor
			)
			.pipe(
				withLatestFrom(this.planService.fetchList()),
				map(([planCalendars, plans]) => {
					const planEvents: EventInput[] = [];
					const planCalendar = !!this.teamLeaderFor
						? planCalendars.find((pc) => pc.employee_id === Number(this.teamLeaderFor))
						: planCalendars[0];
					if (planCalendar) {
						if (planCalendar.ListOfPlanItem?.length > 0) {
							planEvents.push(
								...planCalendar.ListOfPlanItem.map((i) => {
									const plan = plans.find((p) => p.id === i.plan_id);
									return this.calendar.getApi().view.type === 'dayGridMonth'
										? ({
												// Global event settings
												id: `${plan.id}`,
												title: plan.name,
												start: i.plan_date,
												end: i.plan_date,
												color: plan.color.toString('hex'),
												allDay: true,
												// editable: !!schedulerData.fehlzeit_name,,
										  } as EventInput)
										: ({
												start: `${i.plan_date.split('T').reverse().pop()}T${i.begin_time.split('T').pop()}`,
												end: `${i.plan_date.split('T').reverse().pop()}T${i.end_time.split('T').pop()}`,
												display: 'background',
												backgroundColor: plan.color.toString('hex'),
												title: plan.name,
												extendedProps: {
													isPlan: true,
													plan: i,
												},
										  } as EventInput);
								})
							);
							this.calendar.getApi().getEventSourceById('').refetch();
						}
					}
					return planEvents;
				})
			);
	}

    refreshCalendar() {
        const calendarApi = this.calendar.getApi();

        calendarApi.refetchEvents();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

	ngOnInit() {
        this.myTxApiService.callAPI('getSetting', { settingName: 'BLOCK_DATE' }).subscribe(
            (response) => {
                this.cutoffDate = response.settingString;
                this.cutoffDateLoaded$.next(true);
            },
            (error) => {
                console.log('ERROR' + error);
                this.cutoffDateLoaded$.next(true);
            }
        );

        this.subscription = this.calendarService.refreshCalendar$.subscribe(() => {
            this.refreshCalendar();
        });

		let workFlowTypenJsonBody = {};
		if (!this.defaultDate) {
			this.defaultDate = moment().format('YYYY-MM-DD');
		}

		setTimeout(() => {
			this.calendar.getApi()?.gotoDate(this.defaultDate);
		}, 100);

		if (this.persoId) {
			this.teamLeaderFor = this.persoId;
			workFlowTypenJsonBody = { team_leader: 1 };
		}

		if (!this.teamLeaderFor) {
			if (this.session.calendar_bde !== 0) {
				this.myBuchungsTyp = [{ label: 'Auftrag', value: 'BDE' }, ...this.myBuchungsTyp];
			}
			if (this.session.calendar_pze !== 0) {
				this.myBuchungsTyp = [{ label: 'Kommen/Gehen', value: 'PZE' }, ...this.myBuchungsTyp];
			}
		} else {
			this.myBuchungsTyp = [
				{ label: 'Kommen/Gehen', value: 'PZE' },
				{ label: 'Auftrag', value: 'BDE' },
				...this.myBuchungsTyp,
			];
		}

		Messenger.options = {
			extraClasses: 'messenger-fixed messenger-on-top',
			theme: 'flat',
		};

		const myRequest = this.myTxApiService.callAPI('getBDE', {}).subscribe(
			(myRequestData) => {
				if (myRequestData !== null) {
					this.myBdeProtokoll = new TxBDEProtokoll(
						myRequestData.statuscode,
						myRequestData.statustext,
						myRequestData.ListOfAuftrag
					);
					this.fullAuftragsArray = this.myBdeProtokoll.ListOfAuftrag;
					this.myAuftragsArray = this.fullAuftragsArray;
				}
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);

		const myKSTRequest = this.myTxApiService.callAPI('getKST', {}).subscribe(
			(myRequestData) => {
				if (myRequestData !== null) {
					for (let i = 0, l = myRequestData.ListOfKST.length; i < l; i++) {
						this.myCalendarKSTArray.push(
							new TxBdeKST(myRequestData.ListOfKST[i].nummer, myRequestData.ListOfKST[i].bezeichnung)
						);
					}
				}
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);

		const myWorkflowTypData: Observable<TxWorkflowTypen> = this.myTxApiService.callAPI(
			'getWorkflowTyp',
			workFlowTypenJsonBody
		);
		myWorkflowTypData.subscribe(
			(response) => {
				response.ListOfWorkflowTyp.map((data) => {
					this.myListOfWorkflowTyp.push({
						label: data.bezeichnung,
						value: data.nummer,
					});
				});
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);

		this.myEditForm = this.fb.group({
			id: new FormControl({ value: '', disabled: false }, []),
			type: new FormControl({ value: '', disabled: false }, []),
			kst: new FormControl({ value: '', disabled: false }, []),
            timemodel_id: new FormControl({ value: '', disabled: false }, []),
			auftrag: new FormControl({ value: '', disabled: false }, []),
			schritt: new FormControl({ value: '', disabled: false }, []),
			fehlzeit: new FormControl({ value: '', disabled: false }, []),
			datum: new FormControl({ value: '', disabled: false }, [Validators.required]),
			start: new FormControl({ value: '', disabled: false }, [Validators.required]),
			ende: new FormControl({ value: '', disabled: false }, [Validators.required]),
			beginn_id: new FormControl({ value: '', disabled: false }, []),
			ende_id: new FormControl({ value: '', disabled: false }, []),
			info: new FormControl({ value: '', disabled: false }, []),
			fzstart: new FormControl({ value: '', disabled: false }, []),
			fzende: new FormControl({ value: '', disabled: false }, []),
			quantity: new FormControl({ value: '', disalbed: false }, []),
		});

		this.myCreateForm = this.fb.group({
			type: new FormControl('PZE', []),
			auftrag_nummer: new FormControl('', []),
			book_beginn: new FormControl('', []),
			book_end: new FormControl({ value: '', disabled: false }, []),
			schritt_nummer: new FormControl({ value: '', disabled: false }, []),
			fehlzeit_id: new FormControl({ value: '', disabled: false }, []),
			kst_nummer: new FormControl(null, []),
            timemodel_id: new FormControl({ value: '', disabled: false }, []),
			info: new FormControl(null, []),
			fzstart: new FormControl(null, []),
			fzende: new FormControl(null, []),
			quantity: new FormControl({ value: 0, disabled: false }, []),
			datum: new FormControl(null, []),
			request_status: new FormControl(null, []),
			request_datum: new FormControl(null, []),
			request_time: new FormControl(null, []),
			request_hint: new FormControl(null, []),
		});

		this.TypeNeuItems.push({
			value: null,
			label: 'Bitte Buchungsart wählen',
			typ: '',
		});

		TYPNEU.forEach((TypNeu: { label: string; Typ: string; value: number }) => {
			this.TypeNeuItems.push({
				value: TypNeu.value,
				label: TypNeu.label,
				typ: TypNeu.Typ,
			});
		});

		this.myEditFormChanges();
		this.myCreateFormChanges();
	}

	loadDefaultSettings(): void {
		const calendarApi = this.calendar.getApi();

		let hourMode: string | null = localStorage.getItem('txSchedulerDefaultIntervalMode');
		if (hourMode !== null) {
			if (hourMode !== 'auto') {
				this.hourMode = parseInt(hourMode);
				//calendarApi.setOption('height', this.hourMode);
				calendarApi.scrollToTime('06:00');
			} else {
				this.hourMode = hourMode;
				//calendarApi.setOption('height', this.hourMode);
			}
		}

		let slotMode = localStorage.getItem('txSchedulerDefaultSlotDuration');
		if (!!slotMode) {
			calendarApi.setOption('slotDuration', slotMode);
		}
	}

	myEditFormChanges(): void {
		this.myEditForm.controls.type.valueChanges.subscribe((val) => {
			if (val === 'BDE') {
				this.chooseEditInputState(this.session.calendar_bde !== 1);
			} else if (val === 'PZE') {
				this.chooseEditInputState(false);
			}
		});

		this.myEditForm.controls.auftrag.valueChanges.subscribe((val) => {
			if (val) {
				const currentAuftrag = this.myAuftragsArray.find((data) => data.auftragsnummer === val);
				this.myEditForm.controls.schritt.reset();
				if (currentAuftrag.ListOfAuftragsschritt && currentAuftrag.ListOfAuftragsschritt.length > 1) {
					this.myAuftragsschrittArray = currentAuftrag.ListOfAuftragsschritt;
					this.myEditForm.controls.schritt.setValidators([Validators.required]);
					this.myEditForm.controls.schritt.enable();
				} else {
					this.myEditForm.controls.schritt.disable();
					this.myEditForm.controls.schritt.setValidators([]);
				}
			} else {
				this.myEditForm.controls.schritt.disable();
				this.myEditForm.controls.schritt.setValidators([]);
			}

			// console.log('set valueChanges', this.myCreateForm.controls.schritt_nummer.hasValidator(Validators.required));
		});
	}

	myCreateFormChanges(): void {
		this.myCreateForm.controls.auftrag_nummer.valueChanges.subscribe((val) => {
			const currentAuftrag = this.myAuftragsArray.find((data) => data.auftragsnummer === val);

			if (currentAuftrag) {
				if (currentAuftrag.hasOwnProperty('ListOfAuftragsschritt')) {
					this.myAuftragsschrittArray = currentAuftrag.ListOfAuftragsschritt;
					if (currentAuftrag.ListOfAuftragsschritt.length >= 1) {
						this.myCreateForm.controls.schritt_nummer.setValidators([Validators.required]);
						//this.myCreateForm.controls.schritt_nummer.enable();
					} else {
						//this.myCreateForm.controls.schritt_nummer.disable();
						this.myCreateForm.controls.schritt_nummer.setValidators([]);
					}
				}
			}

			/*console.log(
				'set myCreateFormChanges',
				this.myCreateForm.controls.schritt_nummer.hasValidator(Validators.required)
			);*/
		});

		this.myCreateForm.controls.type.valueChanges.subscribe((val) => {
			if (val) {
				this.dialogType = val;

				switch (val) {
					case 'BDE':
						this.myCreateForm.controls.auftrag_nummer.setValidators([Validators.required]);
						this.myCreateForm.controls.kst_nummer.setValidators([]);
						this.myCreateForm.controls.fehlzeit_id.setValidators([]);
						break;
					case 'PZE':
						this.myCreateForm.controls.auftrag_nummer.setValidators([]);
						this.myCreateForm.controls.kst_nummer.setValidators([]);
						this.myCreateForm.controls.fehlzeit_id.setValidators([]);
						this.myCreateForm.controls.schritt_nummer.setValidators([]);
						break;
					case 'FZ':
						this.myCreateForm.controls.auftrag_nummer.setValidators([]);
						this.myCreateForm.controls.kst_nummer.setValidators([]);
						this.myCreateForm.controls.fehlzeit_id.setValidators([Validators.required]);
						this.myCreateForm.controls.schritt_nummer.setValidators([]);
						break;
					default:
						break;
				}
			}

			this.myCreateForm.patchValue({
				auftrag_nummer: '',
				schritt_nummer: '',
				kst_nummer: '',
                timemodel_id: '',
				fehlzeit_id: '',
			});
		});
	}

	chooseEditInputState(state): void {
		if (state) {
			this.myEditForm.controls.datum.disable();
			this.myEditForm.controls.start.disable();
			this.myEditForm.controls.ende.disable();
		} else {
			this.myEditForm.controls.datum.enable();
			this.myEditForm.controls.start.enable();
			this.myEditForm.controls.ende.enable();
		}
	}

	/* WLT-3971 */
	selectStatus(aEvent: any): void {
		this.myCreateAntragModell.Art = this.getStatusByID(aEvent.value);
	}

	getStatusByID(aID: number) {
		for (let i = 0, l = this.TypeNeuItems.length; i < l; i++) {
			if (this.TypeNeuItems[i].value === aID) {
				return this.TypeNeuItems[i].typ;
			}
		}
	}

	ngAfterViewInit(): void {
        this.cutoffDateLoaded$.pipe(
            filter(loaded => loaded),
            take(1)
        ).subscribe(() => {
            const calendarApi = this.calendar.getApi();

            calendarApi.setOption(
                'selectable',
                !(this.session.calendar_pze === 0 && this.session.calendar_bde === 0) || this.teamLeaderFor !== undefined
            );

            if (this.cutoffDate) {
                const cutoffMoment = moment(this.cutoffDate).endOf('day');

                calendarApi.setOption('selectConstraint', {
                    start: cutoffMoment.add(1, 'day').startOf('day').format('YYYY-MM-DD')
                });

                calendarApi.setOption('selectAllow', (selectInfo) => {
                    return moment(selectInfo.start).isAfter(cutoffMoment);
                });

                calendarApi.setOption('dateClick', (dateClickInfo) => {
                    if (moment(dateClickInfo.date).isSameOrBefore(cutoffMoment)) {
                        Messenger().post({
                            message: this.translate.instant('txScheduler.blockedDateMessage'),
                            type: 'error',
                            showCloseButton: true,
                        });
                    }
                });
            }

		this.loadDefaultSettings();
    });
	}

	getDefaultView() {
		const defaultView = localStorage.getItem('txSchedulerDefaultView41');
		const availableViewsArray = this.calendarOptionsViews;

		if (defaultView in availableViewsArray) {
			return defaultView;
		}
		return '';
	}

	async saveCalendarEntityValues(k: keyof CalendarEntityConfig, value: typeof k) {
		// @ts-ignore
		if (!!value && value !== 'FZ') {
			this.appConfigService.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
				if (!config.CalendarEntityConfig) {
					config.CalendarEntityConfig = new CalendarEntityConfig();
				}

				// @ts-ignore
				config.CalendarEntityConfig[k] = value;

				this.appConfigService.setPersonalAccountsConfig(config);
			});
		}
	}
}
