<ng-container
        *ngIf="{
    UserInfo: theCurrentUserInfo$ | async,
    Online: networkIsOnline$ | async,
    Session: session$ | async
} as Data">
    <div class="container-fluid" *ngIf="pinMode">
        <!-- .navbar-header contains links seen on xs & sm screens -->
        <div class="navbar-header">
            <ul class="nav navbar-nav navbar-right">
                <li class="nav-item" *ngIf="Data.Online">
                    <!-- toggles chat -->
                    <a class="nav-link" [routerLink]=" ['/login'] " (click)="logout()">
                        <i class="fas fa-sign-out-alt"></i> &nbsp; Log Out
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="container-fluid" *ngIf="!pinMode">
        <!-- .navbar-header contains links seen on xs & sm screens -->
        <div class="navbar-header px-4">
            <ul class="nav navbar-nav">
                <li class="nav-item">
                    <a
                            (click)="toggleSidebar('collapse')"
                            class="hidden-xl-up nav-link"
                            href="javascript:void(0)"
                            data-html="true"
                            title="Show/hide<br>sidebar"
                            data-placement="bottom"
                    >
                        <span style="position: relative; display: inline-block;">
        <i class="fa fa-bars fa-lg"></i>
        <span *ngIf="this.badgeNumber > 0"
                style="
              position: absolute;
              top: -5px;
              right: -18px;
              background-color: red;
              color: white;
              border-radius: 50%;
              padding: 2px 6px;
              font-size: 10px;
            ">
          {{this.badgeNumber}}
        </span>
      </span>
                        <i class="fa fa-bars fa-lg hidden-xl-down"></i>
                    </a>
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-right hidden-md-up">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]=" ['/help']" style="font-size: 21px">
                        <i class="fa fa-question-circle fa-lg"></i>
                        <span class="hidden-md-down">Hilfe</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="Data.Online">
                    <!-- toggles chat -->
                    <a class="nav-link" href="javascript:void(0)" (click)="toggleChat()">
                        <span class="rounded rounded-lg bg-white text-gray"><i class="fa fa-globe fa-lg"></i></span>
                    </a>
                </li>
                <li *ngIf="!Data.Online">
                    <a
                            class="nav-link"
                            href="javascript:void(0)"
                            (click)="alert('Sie sind zuzeit offline. Ihre Buchungen werden übermittelt sobald Sie online gehen.')">
                        <span class="rounded rounded-lg bg-white text-gray"><i
                                class="fa fa-exclamation-triangle fa-lg"></i></span>
                    </a>
                </li>
            </ul>
            <a
                    class="navbar-brand md:hidden full-height flex align-items-center justify-content-center"
                    [routerLink]=" ['/'] ">
                &nbsp;<img src="{{config.logoDark}}" alt="{{config.name}}" title="{{config.name}}"/>
            </a>
        </div>
        <!-- this part is hidden for xs screens -->
        <div class="hidden md:block navbar-collapse">
            <ul class="nav navbar-nav pull-xs-right" (click)="$event.preventDefault()">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]=" ['/help']">
                        <fa-icon icon="question-circle" size="lg"></fa-icon>
                        <span class="ml-2 hidden-md-down">{{ 'navbar.help' | translate }}</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" (click)="reload()">
                        <fa-icon icon="sync"></fa-icon>
                    </a>
                </li>

                <li class="nav-item dropdown">
                    <a
                            class="nav-link dropdown-toggle dropdown-toggle-notifications"
                            id="notifications-dropdown-toggle"
                            data-toggle="dropdown">
                        <strong>
                            <i class="fa fa-circle text-{{Data.UserInfo?.status}} pull-left mr-lg"></i>
                            {{ Data.UserInfo?.name }} {{ Data.UserInfo?.state_id ? '(' + Data.UserInfo.state_name + ')' : '' }}
                        </strong
                        >&nbsp;
                        <b class="caret"></b>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li>
                            <a class="dropdown-item" [routerLink]=" ['/account'] ">
                                <i class="fas fa-user"></i>
                                &nbsp; {{ 'navbar.settings' | translate }}
                            </a>
                        </li>
                        <li class="dropdown-divider"></li>
                        <li>
                            <a class="dropdown-item" [routerLink]=" ['/login'] " (click)="logout()"
                            ><i class="fas fa-sign-out-alt"></i> &nbsp; Log Out</a
                            >
                        </li>
                    </ul>
                </li>

                <li class="nav-item" *ngIf="Data.Online">
                    <!-- toggles chat -->
                    <a class="nav-link" href="javascript:void(0)" (click)="toggleChat()" id="toggle-chat">
                        <i class="fa fa-globe fa-lg hidden-lg-up"></i>
                        <span class="hidden-md-down">{{ 'navbar.whoIsPresent' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!Data.Online">
                    <a class="nav-link" href="javascript:void(0)" (click)="toggleOfflineAlert()">
                        <i class="fa fa-exclamation-triangle fa-lg hidden-lg-up"></i>
                        <span class="hidden-md-down">Sie sind offline</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <p-dialog header="Sie sind offline" [(visible)]="offlineAlert" [style]="{'width': '300'}" [responsive]="true">
        <p>Sie sind derzeit offline. Ihre Buchungen werden übermittelt sobald Sie wieder online.</p>
        <p-footer>
            <button type="button" pButton icon="fa fa-check" (click)="toggleOfflineAlert()" label="Ok"></button>
        </p-footer>
    </p-dialog>
</ng-container>
